import http from "./httpService";

const apiEndpoint = http.apiUrl + "/questionnaire";

function questionnaireURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getQuestionnaires() {
  return http.get(apiEndpoint);
}

export function getQuestionnaire(questionnaireId) {
  return http.get(questionnaireURl(questionnaireId));
}

export function createQuestionnaire(questionnaire) {
  return http.post(apiEndpoint, questionnaire);
}

export function updateQuestionnaire(questionnaireId, questionnaire) {
  return http.patch(questionnaireURl(questionnaireId), questionnaire);
}

export function deleteQuestinnaire(questionnaireId) {
  return http.delete(questionnaireURl(questionnaireId));
}
