import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components";
import { AssignQuestionnaireItem } from "../components/DraftQuestions";
import {
  setAllQuestionnaires,
  setErrorForQuestionnaire,
} from "../../../stores/responderQuestionnaireReducer";
import { fetchAllResponderAssignQuestionnaires } from "../../../services/responderQuestionnaireService";

const fetchQuestionnaire = (history) => async (dispatch) => {
  try {
    const response = await fetchAllResponderAssignQuestionnaires();
    const data = await response.data;
    dispatch(setAllQuestionnaires(data.questionnaireList));
    return data;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      history.push("/not-found");
    }
    dispatch(setErrorForQuestionnaire());
  }
};

function Responder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const { loading, error, questionnaireList } =
    state.entities.assignQuestionnaires;

  useEffect(() => {
    // dispatch(fetchSignleQuestionnaire(id));
    dispatch(fetchQuestionnaire(history));
  }, [dispatch, history]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="heading3 text-center">{error}</h3>;
  }

  return (
    <div>
      {questionnaireList.map((questionnaire) => (
        <AssignQuestionnaireItem
          draftQuestion={questionnaire}
          key={questionnaire._id}
        />
      ))}
    </div>
  );
}

export default Responder;
