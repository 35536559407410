import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./formField.module.css";

function CheckBoxGroup(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control">
      <div className={styles.title}>
        {label && <label className={styles.label}>{label}</label>}
      </div>
      <br />
      <Field name={name}>
        {({ field }) => {
          return (
            <div className={styles.checkboxContainer}>
              {options.map((option) => (
                <div key={option.key} style={{ margin: "5px 0px" }}>
                  <input
                    className={`${styles.radioInput} ${styles.checkbox}`}
                    type="checkbox"
                    id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value.includes(option.value)}
                  />
                  <label
                    htmlFor={option.value}
                    className={`text-sm1 ${styles.label}`}
                  >
                    {option.key}
                  </label>
                  <br />
                </div>
              ))}
            </div>
          );
        }}
      </Field>
      <ErrorMessage
        component="div"
        name={name}
        className={styles.errorMessage}
      />
    </div>
  );
}

export default CheckBoxGroup;
