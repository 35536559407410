import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { setJWT, getCurrentUser } from "../services/authService";
import http from "../services/httpService";

function AppToken() {
  const params = useParams();

  if (getCurrentUser() === null) {
    setJWT(params.token);
    http.setJwt(params.token);
  }

  return <Redirect to="/dashboard" />;
}

export default AppToken;
