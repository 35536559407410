const statusList = {
  open: "Viewed",
  click: "Clicked",
  notAssigned: "Not Assigned",
  notDelivered: "Not Delivered",
  viewed: "Viewed",
  delivered: "Delivered",
  readyForReview: "Ready for Review",
  awaitingInfo: "Awaiting Info",
  declined: "Declined",
  completed: "Completed",
  assigned: "Assigned",
  "in-progress": "In-Progress",
};

export default statusList;
