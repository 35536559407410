import React from "react";
import styles from "./clickToAssign.module.css";

function AssignStatus({ status = "notAssigned", statusText = "Not Assigned" }) {
  let statusElement;

  if (status === "assigned" || status === "completed") {
    statusElement = (
      <p className={`${styles.subtitle} text-sm2 ${styles.assigned}`}>
        {statusText}
      </p>
    );
  } else if (status === "declined" || status === "notDelivered") {
    statusElement = (
      <p className={`${styles.subtitle} text-sm2  ${styles.declined}`}>
        {statusText}
      </p>
    );
  } else if (status === "readyForReview") {
    statusElement = (
      <p className={`${styles.subtitle} text-sm2 ${styles.ready}`}>
        {statusText}
      </p>
    );
  } else {
    statusElement = (
      <p className={`${styles.subtitle} text-sm2`}>{statusText}</p>
    );
  }

  return (
    <div>
      <p className={styles.title}>STATUS</p>
      {statusElement && statusElement}
    </div>
  );
}

export default AssignStatus;
