import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import Container from "./Container";
import AssignToSomeone from "./AssignToSomeone";
import { Card } from "../../components/UI/";
import { Button, Loader } from "../../components";
import { QuestionnaireButton } from "../Dashboard/components/";
import { fetchAllAssignedQuestions } from "../../services/responderService";
import { assignToAnotherEmail } from "../..//services/assignToAnotherService";
import {
  setAllAssignedQuestionsAndOtherDetails,
  setErrorState,
  setLoadingState,
  getAdditionalInfoQuestionIndex,
} from "../../stores/responderReducer";
import { declinedQuestionsByAPI } from "../../services/declinedQuestionsService";
import useVisible from "../../hooks/useVisible";
import styles from "./emailInvite.module.css";
import QuestionList from "./component/QuestionList";
import AdditionalInfoQuestion from "./component/AdditionalInfoQuestion";

const fetchQuestions = (history, token) => async (dispatch) => {
  dispatch(setLoadingState(true));

  try {
    const response = await fetchAllAssignedQuestions(token);
    const data = await response.data;
    dispatch(
      setAllAssignedQuestionsAndOtherDetails({
        questions: data.assignedQuestionList,
        questionnaire: data.questionnaire,
      })
    );
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      history.push("/not-found");
    } else {
      dispatch(setErrorState());
    }
  }
};

function ResponderAccept() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShow, toggleShow] = useVisible();
  const [anotherAssignedUser, setAnotherAssignedUser] = useState("");
  const [declinedQuestions, setDeclinedQuestions] = useState(false);
  const { questions, isLoading, responder, questionnaire, error } = useSelector(
    (state) => state.entities.responder
  );
  const additionInfoQuesIndex = useSelector(getAdditionalInfoQuestionIndex);

  useEffect(() => {
    dispatch(fetchQuestions(history, token));
  }, [dispatch, history, token]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="heading3 text-center">{error}</h3>;
  }

  if (!questions) {
    return <Loader />;
  }

  const handleRespondToRequest = () => {
    history.push(
      `/email-invite/response/${token}/${
        questions[additionInfoQuesIndex] ? additionInfoQuesIndex : 0
      }`
    );
  };

  const handleAssignToAnother = async (emailObj) => {
    try {
      await assignToAnotherEmail(emailObj.email, token);

      setAnotherAssignedUser(emailObj.email);
      toggleShow();
      toast.success(`Sucessfully Assign to ${emailObj.email}!`);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
        toast.error("Not able to assign!");
      }
    }
  };

  const handleDeclinedQuestions = async () => {
    try {
      setDeclinedQuestions(true);
      await declinedQuestionsByAPI(token);

      toast.success(`Sucessfully Declined Questions`);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able to declined!");
        setDeclinedQuestions(false);
      }
    }
  };

  let errorText;

  if (questions.length === 0) {
    errorText = (
      <h3 className="heading3 text-center">
        Unfortunately, there are no questions for you to review. Please contact
        admin of the questionnaire if needed.
      </h3>
    );
  }

  if (questionnaire.status === "pause") {
    errorText = (
      <h3 className="heading3 text-center">
        The admin of the questionnaire has paused the responses temporarily.
      </h3>
    );
  }

  if (moment().isAfter(questionnaire.dueDate)) {
    errorText = (
      <h3 className="heading3 text-center">
        We are sorry. The requested questionnaire has passed the due date.
      </h3>
    );
  }

  return (
    <Container email={responder?.assignedUser}>
      {errorText ? (
        errorText
      ) : (
        <Card>
          <div className={styles.mainContent}>
            <div className={styles.questionContainer}>
              <h4 className="heading4">
                <span className="capitallize">{responder?.user?.fullName}</span>
                ,{" "}
                <span className="capitallize">
                  {responder?.user?.designation}
                </span>
                ({responder?.user?.email}) is requesting information from you
              </h4>
              {questions[additionInfoQuesIndex] ? (
                <AdditionalInfoQuestion
                  question={questions[additionInfoQuesIndex]}
                />
              ) : (
                <QuestionList questions={questions} />
              )}
            </div>
            <div className={styles.descriptionContainer}>
              <h4 className="heading4">Why is this information needed?</h4>
              <h5 className="heading5">{questionnaire.importance}</h5>
              <h5 className="heading5">
                Please respond to this before the due-date on{" "}
                <span className="text--bold ">
                  {moment(questionnaire.dueDate).format("Do MMM, YYYY")}
                </span>
              </h5>
            </div>

            <div className={styles.buttonsContainer}>
              <div className={styles.container}>
                <div className={styles.sigleButtonContainer}>
                  <Button
                    buttonText={
                      !anotherAssignedUser
                        ? "Reassign all Questions"
                        : "Assigned"
                    }
                    isBackground={false}
                    size={1.8}
                    onClick={() => toggleShow()}
                    disabled={anotherAssignedUser || declinedQuestions}
                  />
                </div>
                <div
                  className={`${styles.sigleButtonContainer} ${styles.lastChild}`}
                >
                  <Button
                    buttonText="respond to request →"
                    size={1.8}
                    onClick={handleRespondToRequest}
                    disabled={anotherAssignedUser || declinedQuestions}
                  />
                </div>
                <AssignToSomeone
                  isShow={isShow}
                  toggleShow={toggleShow}
                  onSubmit={handleAssignToAnother}
                  email={responder?.assignedUser}
                />
              </div>
              {anotherAssignedUser && (
                <p className={styles.anotherAssigendUser}>
                  ASSIGNED TO {anotherAssignedUser}
                </p>
              )}
              <p className={`text-sm3 ${styles.note}`}>
                <b> Note:</b> You can always individually assign the questions
                to someone else while you are responding.
              </p>
              <div className={styles.declineButton}>
                <QuestionnaireButton
                  buttonText="DECLINE THIS REQUEST"
                  buttonType="delete"
                  onClick={handleDeclinedQuestions}
                  disabled={anotherAssignedUser || declinedQuestions}
                />
              </div>
            </div>
          </div>
        </Card>
      )}
    </Container>
  );
}

export default ResponderAccept;
