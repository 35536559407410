import React from "react";
import * as Yup from "yup";
import { Form, FormButton } from "../../../components/Forms";
import { FormField } from ".";
import styles from "./editSectionTitle.module.css";
import trimObjectValues from "../utils/trimObject";

const validationSchema = Yup.object().shape({
  sectionName: Yup.string().required().min(5).label("Section").trim(),
});

function EditSectionTitle({ section, editMode, onSubmit, onCancel }) {
  if (editMode === false) return null;

  return (
    <Form
      initialValues={section}
      onSubmit={(data, otherFormObj) => {
        data = trimObjectValues(data);
        onSubmit(data, otherFormObj);
      }}
      validationSchema={validationSchema}
    >
      <div className={styles.formContainer}>
        <FormField
          element="input"
          name="sectionName"
          placeholder="Section Name"
          autoFocus={true}
        />
        <div className={styles.buttonContainer}>
          <FormButton
            buttonText="CANCEL"
            isBackground={false}
            onClick={() => onCancel()}
            type="button"
          />
          <FormButton buttonText="save" type="submit" submitButton={true} />
        </div>
      </div>
    </Form>
  );
}

export default EditSectionTitle;
