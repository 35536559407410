import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import { Button, SpinnerLoader } from "../../../components";
import AssignToSomeone from "../AssignToSomeone";
import {
  ConfirmationModel,
  QuestionnaireButton,
} from "../../Dashboard/components";
import modalText from "../../modalText";
import styles from "./questionForm.module.css";
import styles2 from "../emailInvite.module.css";

const dropzoneStyle = {
  width: "100%",
  height: "auto",
  borderWidth: 2,
  borderColor: "rgb(102, 102, 102)",
  borderStyle: "dashed",
  borderRadius: 5,
};

const waitTime = 1500;

class SameQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.initialValues,
      charCount: 0,
      isShow: false,
      showConfirmationMode: false,
      anotherAssignedUser: "",
      error: {},
      timer: waitTime,
      isAutoSaving: false,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({ formData: nextProps.initialValues });
  // }

  static getDerivedStateFromProps(props, state) {
    if (props.initialValues.questionId !== state.formData.questionId) {
      return {
        formData: props.initialValues,
        charCount: 0,
        isShow: false,
        showConfirmationMode: false,
        anotherAssignedUser: "",
        error: {},
        timer: waitTime,
        isAutoSaving: false,
      };
    }
    return null;
  }

  toggleAutoSaving = () => {
    this.setState((state) => ({
      ...state,
      isAutoSaving: !this.state.isAutoSaving,
    }));
  };

  handleSubmitResponse = async (otherFormObj, status) => {
    this.props.onSubmit(this.state.formData, otherFormObj, status);
  };

  setResponseData = () => {
    this.setState((state) => ({
      ...state,
      formData: { answer: "", files: null },
    }));
  };

  resetForm = () => {
    this.setState((state) => ({
      ...state,
      formData: { answer: "", files: null },
    }));
  };

  toggleShow = () => {
    this.setState((state) => ({
      ...state,
      isShow: !state.isShow,
    }));
  };

  toggleShowConfirmationMode = () => {
    console.log(
      "calling the toggle confirmation  1 : ",
      this.state.showConfirmationMode
    );

    console.log(
      "calling the toggle confirmation 2 : ",
      !this.state.showConfirmationMode
    );
    this.setState((state) => ({
      ...state,
      showConfirmationMode: !this.state.showConfirmationMode,
    }));
  };

  handleConfirmModal = () => {
    this.props.toggleTryForResponse();
    this.toggleAutoSaving();

    const otherFormObj = {};
    otherFormObj.resetForm = this.resetForm;
    otherFormObj.toggleShowConfirmationMode = this.toggleShowConfirmationMode;
    otherFormObj.toggleDisableButtonWhileAutoSaving = this.toggleAutoSaving;

    this.handleSubmitResponse(otherFormObj, "readyForReview");
  };

  setAnotherAssignedUser = (assignedUserVal) => {
    this.setState((state) => ({
      ...state,
      anotherAssignedUser: assignedUserVal,
    }));
  };

  setTimer = (timer) => {
    this.setState((state) => ({
      ...state,
      timer: timer,
    }));
  };

  handleAssignToAnother = async (anotherUser) => {
    this.props.onAssign(anotherUser);
    this.toggleShow();
    this.setAnotherAssignedUser("");
  };

  handleChange = (value, name) => {
    const { formData } = { ...this.state };
    const currFormData = formData;

    currFormData[name] = value;
    this.setState((state) => ({
      ...state,
      formData: currFormData,
    }));
  };

  handleCharCount = (count) => {
    this.setState((state) => ({
      ...state,
      charCount: count,
    }));
  };

  doSomeThingWhenStopTyping = async () => {
    const otherFormObj = {};

    const isFormValid = this.handleValidation();
    if (this.props.isEditMode && isFormValid === false) {
      return;
    }

    otherFormObj.resetForm = () => {
      if (this.state.formData.files) this.handleChange(null, "files");

      return true;
    };

    this.toggleAutoSaving();

    otherFormObj.toggleDisableButtonWhileAutoSaving = this.toggleAutoSaving;

    this.handleSubmitResponse(
      otherFormObj,
      this.props.isEditMode ? "readyForReview" : "in-progress"
    );
  };

  handleClearButton = () => {
    this.toggleAutoSaving();

    const otherFormObj = {};
    otherFormObj.resetForm = this.resetForm;
    otherFormObj.toggleDisableButtonWhileAutoSaving = this.toggleAutoSaving;
    this.props.onClear(otherFormObj, "in-progress");
  };

  handleResponseTextChange = (newValue, count) => {
    this.handleChange(newValue, "answer");
    this.handleCharCount(count);

    if (count > 10) {
      this.setState((state) => ({
        ...state,
        error: { answer: "" },
      }));
    }
  };

  handleValidation = () => {
    let error = {};
    let isFormValid = true;

    const { formData } = this.state;

    if (!formData["answer"]?.trim()) {
      isFormValid = false;
      error["answer"] = "Cannot be empty";
    }

    const charSet = this.props.charLimit;

    if (this.state.charCount <= 10) {
      isFormValid = false;
      error["answer"] = `answer should have more than 10 character.`;
    }

    if (charSet && this.state.charCount > charSet) {
      isFormValid = false;
      error[
        "answer"
      ] = `answer should not have more than ${charSet} characters.`;
    }

    this.setState((state) => ({
      ...state,
      error: error,
    }));

    return isFormValid;
  };

  render() {
    return (
      <>
        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              const isFormValid = this.handleValidation();
              if (isFormValid) {
                this.toggleShowConfirmationMode();
              }
              this.props.toggleTryForResponse();
            }}
          >
            <div className={styles.container}>
              <div className={styles.formContainer}>
                <Editor
                  onKeyUp={(e) => {
                    // Clear timer
                    clearTimeout(this.state.timer);

                    // Wait for X ms and then process the request
                    let currTimer = setTimeout(() => {
                      this.doSomeThingWhenStopTyping();
                    }, waitTime);

                    this.setTimer(currTimer);
                  }}
                  apiKey={"3lvy7r8d6ewnc8f74rslfeocg68t5t64exf6hyhwxhc5w3ov"}
                  value={this.state.formData.answer}
                  onInit={(evt, editor) => {
                    this.handleCharCount(
                      // editor.getContent({ format: "text" }).length
                      editor.plugins.wordcount.body.getCharacterCountWithoutSpaces()
                    );
                  }}
                  onEditorChange={(newValue, editor) => {
                    this.handleResponseTextChange(
                      newValue,
                      editor.plugins.wordcount.body.getCharacterCountWithoutSpaces()
                    );
                    if (newValue.length > 0) {
                      this.props.setTryForResponse();
                    }
                  }}
                  init={{
                    remove_linebreaks: false,
                    height: "480",
                    selector: "textarea",
                    skin: "snow",
                    icons: "thin",
                    plugins: ["lists link image paste help wordcount code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help",
                  }}
                />
                <div className={styles.dragContainer}>
                  <Dropzone
                    style={dropzoneStyle}
                    accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf, .xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,audio/*,image/*,.psd,.pdf, text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    maxFiles={1}
                    // maxSize={5}
                    onDrop={(acceptedFiles) => {
                      // do nothing if no files
                      if (acceptedFiles.length === 0) return;

                      this.handleChange(acceptedFiles[0], "files");
                      this.handleChange(
                        acceptedFiles[0].name,
                        "originalFileName"
                      );
                      this.doSomeThingWhenStopTyping();
                      this.props.setTryForResponse();
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <div {...getRootProps({ className: dropzoneStyle })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p level={4} type="secondary">
                            Drop here
                          </p>
                        ) : (
                          <p level={4} type="secondary">
                            <b>Upload Attachment</b>
                            <span> or Drop file here to attach</span>
                            {this.state.formData.files ? (
                              <b> {`${this.state.formData.files.name}`}</b>
                            ) : (
                              <>
                                {this.state.formData?.originalFileName && (
                                  <b>
                                    {`${this.state.formData?.originalFileName}`}
                                  </b>
                                )}
                              </>
                            )}
                          </p>
                        )}

                        {isDragReject && (
                          <p>Only Accept the all type of doc file and images</p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  {this.state.isAutoSaving ? (
                    <SpinnerLoader />
                  ) : (
                    <>
                      <>
                        {!this.props.isEditMode &&
                          (this.state.formData.answer ||
                            this.state.formData?.originalFileName ||
                            this.state.formData.files) && (
                            <QuestionnaireButton
                              buttonText="clear"
                              buttonType="delete"
                              onClick={this.handleClearButton}
                              type="button"
                              disabled={this.state.isAutoSaving}
                            />
                          )}
                      </>
                    </>
                  )}
                </div>
              </div>
              <div className={styles2.errorAndCounterContainer}>
                <div>
                  {this.state.error.answer && (
                    <div className={styles.errorMessage}>
                      {this.state.error.answer}
                    </div>
                  )}
                </div>
                <div>
                  {this.props.charLimit !== 0 && (
                    <h3 className="text-normal2 text-sm1-bold">
                      Limit {this.props.charLimit - this.state.charCount} Char
                    </h3>
                  )}
                </div>
              </div>
            </div>
            <div className={styles2.buttonsContainer}>
              <div className={styles2.container}>
                <div className={styles2.sigleButtonContainer}>
                  {this.props.isEditMode ? (
                    <Button
                      buttonText="Cancel Edit"
                      isBackground={false}
                      size={1.8}
                      onClick={() => this.props.onCancel()}
                      type="button"
                      disabled={this.state.isAutoSaving}
                    />
                  ) : (
                    <Button
                      buttonText={
                        !this.state.anotherAssignedUser
                          ? "ASSIGN TO SOMEONE ELSE"
                          : "Assigned"
                      }
                      isBackground={false}
                      size={1.8}
                      onClick={() => this.toggleShow()}
                      type="button"
                      disabled={
                        this.state.anotherAssignedUser ||
                        this.state.isAutoSaving
                      }
                    />
                  )}
                </div>
                <div
                  className={`${styles2.sigleButtonContainer} ${styles2.lastChild}`}
                >
                  {this.props.isEditMode ? (
                    <Button
                      buttonText="Save Edit response"
                      size={1.8}
                      type="submit"
                      disabled={
                        this.state.anotherAssignedUser ||
                        this.state.isAutoSaving
                      }
                    />
                  ) : (
                    <Button
                      buttonText="SUBMIT RESPONSE  →"
                      size={1.8}
                      type="submit"
                      disabled={
                        this.state.anotherAssignedUser ||
                        this.state.isAutoSaving
                      }
                    />
                  )}
                </div>
                <AssignToSomeone
                  isShow={this.state.isShow}
                  onSubmit={(anotherUser) => {
                    this.handleAssignToAnother(anotherUser);
                  }}
                  toggleShow={this.toggleShow}
                  email={this.props.email}
                />
              </div>
            </div>
            <ConfirmationModel
              title={modalText.submitResponse.title}
              subtitle={modalText.submitResponse.subtitle}
              showModal={this.state.showConfirmationMode}
              toggleModal={this.toggleShowConfirmationMode}
              onConfirm={() => this.handleConfirmModal(this.resetForm)}
            />
          </form>
        </div>
      </>
    );
  }
}

export default SameQuestionForm;
