import React from "react";
import MovingButton from "./MovingButton";
import styles from "./movingButton.module.css";

function MovingButtonContainer({
  onPrev,
  onNext,
  MaxQuestionLength = 0,
  currQuestionNum = 0,
}) {
  return (
    <div className={styles.container}>
      <MovingButton
        title="< See Previous Question"
        onClick={onPrev}
        isDisable={currQuestionNum <= 0 ? true : false}
      />
      <MovingButton
        title="See Next Question >"
        onClick={onNext}
        isDisable={currQuestionNum >= MaxQuestionLength - 1 ? true : false}
      />
    </div>
  );
}

export default MovingButtonContainer;
