import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  Progress,
  // QuestionnaireButton,
  ResendEmailModal,
  ViewCardDueDate,
} from ".";
import {
  getCountOfAllTotalQuestionsStatus,
  getAllDistinctAssignedUser,
} from "../../../stores/QuestionnaireReducer";
import { resendEmailAPIService } from "../../../services/resendEmailService";
import useVisible from "../../../hooks/useVisible";
import modalText from "../../modalText";
import styles from "./viewQuestionCard.module.css";
import {
  generatedDocFileAPI,
  getGeneratedDocFileAPI,
} from "../../../services/generateDocService";

function ResponseQuestionnirCard({ questionnaire }) {
  const statusList = useSelector(getCountOfAllTotalQuestionsStatus);
  const distinctAssignedUserList = useSelector(getAllDistinctAssignedUser);
  const [isShowResendModal, toggleIsShowResendModal] = useVisible(false);
  const [isDueDateExpire, setIsDueDateExpire] = useState(false);

  useEffect(() => {
    setIsDueDateExpire(false);
    if (moment().isAfter(questionnaire.dueDate)) {
      setIsDueDateExpire(true);
    }
  }, [questionnaire.dueDate]);

  const handleResendEmail = async ({ emails }) => {
    if (moment().isAfter(questionnaire.dueDate)) {
      setIsDueDateExpire(true);
    } else {
      setIsDueDateExpire(false);
      const payload = {
        questionnaireId: questionnaire._id,
        assignedUserList: emails,
      };

      try {
        await resendEmailAPIService(payload);

        toast.success(
          "Successfully send emails on all selected email address!"
        );
        toggleIsShowResendModal();
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status <= 409
        ) {
          toast.error("Not able to send email!");
        }
      }
    }
  };

  const downloadAndGenerateDocFile = async () => {
    await generatedDocFileAPI(questionnaire._id);
    const response = await getGeneratedDocFileAPI(questionnaire._id);
    const blob = await response.data;

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${questionnaire._id}.docx`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <>
      <div className={styles.questionCardContainer}>
        <div
          className={styles.questionTextContainer}
          style={{ borderBottom: "none" }}
        >
          <div>
            <div className={styles.questionHeadingContainer}>
              <h2 className={styles.heading2}>
                {questionnaire.questionnaireName}
              </h2>
            </div>
            <div className={styles.container}>
              <Progress
                totalQuestion={questionnaire.totalQuestions}
                complateQuestion={questionnaire.answeredQuestions}
              />
              {distinctAssignedUserList.length !== 0 &&
                questionnaire.status !== "completed" && (
                  <p
                    className={styles.resendEmailButton}
                    onClick={toggleIsShowResendModal}
                  >
                    resend request emails
                  </p>
                )}

              <button
                className={styles.generateFileButton}
                onClick={downloadAndGenerateDocFile}
              >
                Download
              </button>

              {/* <ViewButton
                buttonText="Download"
                onClick={downloadAndGenerateDocFile}
              /> */}
            </div>
            <div className={styles.questionnaireStats}>
              <div className={styles.bottomTextContainer}>
                <ul className={styles.statsContainer}>
                  <li className={styles.completedQuestionStats}>
                    {statusList.completed} questions completed
                  </li>
                  <li className={styles.readyForReviewQuestionStats}>
                    {statusList.readyForReview} questions ready for review
                  </li>
                  <li className={styles.awaitingActionStats}>
                    {statusList.awaitingInfo} questions awaiting action
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ViewCardDueDate date={questionnaire.dueDate} />
        </div>
      </div>
      <ResendEmailModal
        title={modalText.resendEmail.title}
        label={modalText.resendEmail.subtitle}
        showModal={isShowResendModal}
        toggleModal={toggleIsShowResendModal}
        onConfirm={handleResendEmail}
        assignedUserList={distinctAssignedUserList}
        error={
          isDueDateExpire &&
          "Your questionnaire has expired. Please update the expiration date to continue gathering the response."
        }
      />
    </>
  );
}

export default ResponseQuestionnirCard;
