import React from "react";
import styles from "./logo.module.css";
import LogoImage from "./../assets/images/Group 4.png";

function Logo() {
  return (
    <div className={styles.logoContainer}>
      <img src={LogoImage} alt="gather logo" />
    </div>
  );
}

export default Logo;
