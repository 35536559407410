import React, { useRef } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { MdInfoOutline } from "react-icons/md";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import styles from "./showHistory.module.css";

function ShowHistory({ history, show, toggleShow }) {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (show) {
      toggleShow();
    }
  });

  return (
    <div
      onBlur={toggleShow}
      className={`${styles.container} ${styles.historyContainer}`}
      ref={ref}
    >
      <div className={styles.headContainer}>
        <p className={styles.heading}>CHAIN OF RESPONSIBILITY</p>
        <MdInfoOutline
          size={30}
          className={styles.infoIcon}
          onClick={toggleShow}
        />
      </div>
      {history.map((item, index) => {
        const keys = Object.keys(item);
        return (
          <div key={keys[0]}>
            <p className={styles.text}>{item[keys[0]]} </p>
            {index < history.length - 1 ? (
              <AiOutlineArrowDown className={styles.downIcon} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default ShowHistory;
