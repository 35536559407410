import React from "react";
import { Button } from "../../../components";
import { Card } from "../../../components/UI/";
import Portal from "./Portal";
import styles from "./modal.module.css";

function AlertForMoveToNext({
  showModal,
  toggleModal,
  title,
  subtitle,
  onConfirm,
}) {
  if (!showModal) return null;

  return (
    <Portal>
      <div className={styles.modaloverlay} onClick={toggleModal}></div>
      <div className={styles.confirmationModal}>
        <Card>
          <div className={styles.modalContainer}>
            {title && <h3 className="bold-heading">{title}</h3>}
            {subtitle && <p className="text-normal">{subtitle}</p>}
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              buttonText="CANCEL"
              isBackground={false}
              onClick={toggleModal}
            />
            <Button buttonText="CONFIRM" onClick={onConfirm} />
          </div>
        </Card>
      </div>
    </Portal>
  );
}

export default AlertForMoveToNext;
