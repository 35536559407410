import React from "react";
import styles from "./button.module.css";
import DotLoader from "./DotLoader";

function ViewButton({
  buttonText,
  onClick,
  Icon,
  size = 2.2,
  borderRadius = 8,
  isBackground = true,
  loading = false,
  ...otherProps
}) {
  return (
    <button
      className={`${styles.button} ${styles.viewButton} ${
        isBackground ? styles.withBackground : styles.noBackground
      }`}
      onClick={onClick}
      style={{
        borderRadius: `${borderRadius}px`,
      }}
      disabled={loading}
      {...otherProps}
    >
      {Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}
      <span className={styles.buttonText} style={{ fontSize: `${size}rem` }}>
        {loading ? <DotLoader /> : buttonText}
      </span>
    </button>
  );
}

export default ViewButton;
