import http from "./httpService";

const apiEndpoint = http.apiUrl + "/section";

function sectionURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function createSection(section) {
  return http.post(apiEndpoint, section);
}

export function updateSection(sectionId, section) {
  return http.patch(sectionURl(sectionId), section);
}

export function deleteSection(sectionId, section) {
  return http.delete(sectionURl(sectionId), {
    data: section,
  });
}
