import React from "react";
import * as Yup from "yup";
import { FormField, FormDateField } from ".";
import { Form, FormButton } from "../../../components/Forms";
import trimObjectValues from "../utils/trimObject";
import styles from "./modelForm.module.css";

const validationSchema = Yup.object().shape({
  questionnaireName: Yup.string().required().min(5).label("Title").trim(),
  importance: Yup.string().required().min(6).label("Importance").trim(),
  dueDate: Yup.date().required().label("Due Date"),
});

function ModalForm({
  initialValues,
  toggleModal,
  addDraftQuestion,
  submitButtonText = "CREATE DRAFT",
}) {
  const handleSubmitButton = (values) => {
    addDraftQuestion(values);
    toggleModal();
  };

  return (
    <div className={styles.modalForm}>
      <Form
        initialValues={initialValues}
        onSubmit={(data, otherFormObj) => {
          data = trimObjectValues(data);
          handleSubmitButton(data, otherFormObj);
        }}
        validationSchema={validationSchema}
      >
        <div className={styles.formContainer}>
          <FormField
            title="Name of Questionnaire"
            subtitle="Give a descriptive name to the questionnaire"
            element="input"
            name="questionnaireName"
            placeholder="Eg. “ACME RFP Response” or “Questions to the Design Team”"
          />
          <FormField
            title="Importance"
            subtitle="Tell your colleagues why it is important to fill this questionnaire promptly and accurately"
            element="textarea"
            name="importance"
            row={10}
          />
          <FormDateField
            title="Due On"
            subtitle="Set a due date for this questionnaire"
            name="dueDate"
          />

          <div className={styles.buttonsContainer}>
            <FormButton
              buttonText="CANCEL"
              isBackground={false}
              onClick={() => toggleModal()}
              type="button"
            />
            <FormButton
              buttonText={submitButtonText}
              type="submit"
              submitButton={true}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ModalForm;
