import React from "react";
import { monthList } from "../utils/date-formats";
import styles from "./viewCardDueDate.module.css";

function ViewCardDueDate({ date }) {
  if (!date) return null;
  const newDate = new Date(date);

  return (
    <div className={styles.container}>
      <div className={styles.dateCard}>
        <h6>DUE ON</h6>
        <div className={styles.dateCardText}>
          <p>{newDate.getDate()}</p>
          <p>{monthList[newDate.getMonth()].substring(0, 3)}</p>
        </div>
      </div>
    </div>
  );
}

export default ViewCardDueDate;
