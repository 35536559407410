import React from "react";
import ReactTooltip from "react-tooltip";
import { MdInfoOutline } from "react-icons/md";
import { ShowHistory } from ".";
import useVisible from "../../../hooks/useVisible";
import styles from "./clickToAssign.module.css";

function ResponderAssignEmail({ assignEmail, assignList }) {
  const [visible, toggleVisible] = useVisible(false);

  return (
    <div className={styles.container}>
      <div className={styles.responseEmailContainer}>
        <p className={styles.title}>Assigned To</p>
        <p
          className={`${styles.subtitle} text-sm2`}
          data-tip
          data-for={assignEmail}
        >
          {/* {assignEmail.substring(0, 19)} */}
          {assignEmail}
        </p>
        <ReactTooltip
          id={assignEmail}
          place="top"
          effect="solid"
          delayShow={500}
        >
          {assignEmail}
        </ReactTooltip>
      </div>
      {/* <div className={styles.lastChild} ref={ref}> */}
      <MdInfoOutline
        size={30}
        className={styles.infoIcon}
        onClick={toggleVisible}
        // onMouseOver={toggleVisible}
        // onMouseOut={toggleVisible}
      />
      {/* </div> */}
      {visible && (
        <ShowHistory
          history={assignList}
          show={visible}
          toggleShow={toggleVisible}
        />
      )}
      {/* </div> */}
    </div>
  );
}

export default ResponderAssignEmail;
