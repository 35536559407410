import React from "react";
import { Field, ErrorMessage } from "formik";
import styles from "./formField.module.css";

function RadioButtonGroup(props) {
  const { label, name, options, ...rest } = props;
  return (
    <>
      {label && <label className="text-normal">{label}</label>}
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div key={option.key} className={styles.group}>
                <input
                  className={styles.radioInput}
                  type="radio"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.value} className="text-sm1 ">
                  {option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage
        component="div"
        name={name}
        className={styles.errorMessage}
      />
    </>
  );
}

export default RadioButtonGroup;
