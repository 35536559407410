import React from "react";
import { Portal } from ".";
import { Button } from "../../../components";
import { Card } from "../../../components/UI/";
import styles from "./modal.module.css";

function ConfirmationModel({
  showModal,
  toggleModal,
  title,
  subtitle,
  onConfirm,
  error,
}) {
  if (!showModal) return null;

  return (
    <Portal toggleModal={toggleModal}>
      <div className={styles.confirmationModal}>
        <Card>
          <div className={styles.modalContainer}>
            {title && <h3 className="bold-heading">{title}</h3>}
            {subtitle && <p className="text-normal">{subtitle}</p>}
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              buttonText="CANCEL"
              isBackground={false}
              onClick={toggleModal}
            />
            <Button buttonText="CONFIRM" onClick={onConfirm} />
          </div>
          {error && <p className="error m-10">{error}</p>}
        </Card>
      </div>
    </Portal>
  );
}

export default ConfirmationModel;
