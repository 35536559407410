import React from "react";
import { Progress } from ".";
import styles from "./cardStatus.module.css";

function CardStatus({ status, complateQuestion = 0, totalQuestion = 0 }) {
  if (status === "complete") return null;

  if (status === "active" || status === "pause") {
    return (
      <div className={styles.cardContainer}>
        <p className={styles.heading}>status</p>
        <Progress
          complateQuestion={complateQuestion}
          totalQuestion={totalQuestion}
        />
      </div>
    );
  }

  return (
    <div className={styles.cardContainer}>
      <p className={styles.heading}>status</p>
      <div className={styles.container}>
        <p className={styles.containerText}>Draft</p>
      </div>
    </div>
  );
}

export default CardStatus;
