import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { FiPlay } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { Button, Loader } from "../../../components";
import {
  AssignQuestionnirCard,
  AssignSection,
  AssignSectionQuestion,
  ConfirmationModel,
  Header,
} from "../components";
import {
  hasAbleToGatherResponse,
  setErrorForQuestionnaire,
  setQuestionnaire,
} from "../../../stores/QuestionnaireReducer";
import { assignToOuterQuestion } from "../../../stores/QuestionnaireReducer";
import { getQuestionnaire } from "../../../services/questionnaireService";
import { assignToIndividualOuterQuestion } from "../../../services/assignQuestionService";
import { gatherResponseForQuestionnaire } from "../../../services/gatherResponseService";
import useVisible from "../../../hooks/useVisible";
import modalText from "../../modalText";
import styles from "../components/newSection.module.css";

const fetchQuestionnaire = (id, history) => async (dispatch) => {
  try {
    const response = await getQuestionnaire(id);
    const data = await response.data;
    dispatch(setQuestionnaire(data.questionnaire));
    return data;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      history.push("/not-found");
    }
    dispatch(setErrorForQuestionnaire());
  }
};

function AssignQuestionnair() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const isAllowToGatherResponse = useSelector(hasAbleToGatherResponse);
  const [isConfirmVisible, toggleConfirm] = useVisible(false);
  const [isDueDateExpire, setIsDueDateExpire] = useState(false);
  const { loading, error, questionnaire } = state.entities.questionnaire;

  useEffect(() => {
    dispatch(fetchQuestionnaire(id, history));
  }, [dispatch, id, history]);

  useEffect(() => {
    setIsDueDateExpire(false);
    if (moment().isAfter(questionnaire.dueDate)) {
      setIsDueDateExpire(true);
    }
  }, [questionnaire.dueDate]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="heading3 text-center">{error}</h3>;
  }

  if (questionnaire.status === "active") {
    return <Redirect to={`/dashboard/details/${id}`} />;
  }

  const handleGatherResponse = async () => {
    if (moment().isAfter(questionnaire.dueDate)) {
      setIsDueDateExpire(true);
    } else {
      try {
        setIsDueDateExpire(false);
        await gatherResponseForQuestionnaire(id);
        toggleConfirm();
        toast.success("Successfully start gathering the response");
        history.push(`/dashboard/details/${id}`);
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status <= 409
        ) {
          toast.success("not able to start gathering the response");
          history.push(`/dashboard/details/${id}`);
        }
      }
    }
  };

  const handleAssignToOuterQuestionSubmit = async (
    newEmail,
    outerQuestionId
  ) => {
    const payload = {
      questionnaireId: questionnaire._id,
      emailAssignToQuestion: newEmail,
    };

    try {
      await assignToIndividualOuterQuestion(outerQuestionId, payload);
      payload.questionId = outerQuestionId;
      dispatch(assignToOuterQuestion(payload));
      toast.success("Successfully Assigned To Question!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // history.push("/not-found");
        toast.error("Not able to Assigned To Question!");
      }
    }
  };

  const sectionList = questionnaire.sections;
  const questionList = questionnaire.questions;
  return (
    <>
      <div>
        <Header
          title="Assign Responders"
          subtitle="Assign responders to each question, section or even the entire questionnaire. You can change assignees as many times as you want. Once done, click on Gather Responses."
        >
          {isAllowToGatherResponse && (
            <Button
              buttonText="GATHER RESPONSES"
              Icon={FiPlay}
              onClick={() => toggleConfirm()}
              size={1.8}
            />
          )}
        </Header>
        <AssignQuestionnirCard questionnaire={questionnaire} />
        {sectionList.map((section, index) => (
          <AssignSection
            key={section._id}
            index={index}
            section={section}
            questionList={questionList}
            questionnaireId={questionnaire._id}
          />
        ))}
        {questionList.map((question, index) => (
          <div className={styles.questionCardContainer} key={question._id}>
            {/* <div key={index} className={styles.question}> */}
            <AssignSectionQuestion
              question={question}
              style={{ borderColor: "transparent" }}
              onClickToAssign={(email) =>
                handleAssignToOuterQuestionSubmit(email, question._id)
              }
            />
            {/* </div> */}
          </div>
        ))}
      </div>
      <ConfirmationModel
        title={modalText.gatherResponse.title}
        subtitle={modalText.gatherResponse.subtitle}
        showModal={isConfirmVisible}
        toggleModal={toggleConfirm}
        onConfirm={handleGatherResponse}
        error={
          isDueDateExpire &&
          "Your questionnaire has expired. Please update the expiration date to continue gathering the response."
        }
      />
    </>
  );
}

export default AssignQuestionnair;
