import React from "react";
import moment from "moment";
import parse from "html-react-parser";
import styles from "./assignSectionQuestion.module.css";
import styles2 from "./viewQuestionCard.module.css";
import ResponseDifference from "./ResponseDifference";
import useVisible from "../../../hooks/useVisible";

function QuestionResponse({
  answer,
  onDownloadClick,
  showDiff = true,
  oldText = { answerText: "" },
}) {
  const [isShow, toggleIsShow] = useVisible(false);

  return (
    <>
      <div className={styles.questionResponseContainer}>
        <div className={styles.box}></div>
        <div className={`${styles.responseQuestionContainer}`}>
          <p className={styles.heading}>
            {answer.type === "answer" ? "RESPONSE" : "ADDITIONAL REQUEST"}{" "}
            {moment(answer.createdDate).format("h:mm A MMM D")}
          </p>
          <div className={styles.signleResponseContainer}>
            {/* <div> */}
            <div style={{ fontSize: "20px" }} className={styles.responseText}>
              {parse(decodeURI(answer.answerText))}
            </div>
            {/* </div> */}
            {answer.originalFileName && (
              <div className={styles.responseFileName}>
                <p className={styles.heading}>ATTACHMENT</p>
                <a
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    onDownloadClick({
                      fileName: answer.fileName,
                      orignalFileName: answer?.originalFileName,
                    })
                  }
                >
                  {answer?.originalFileName}
                </a>
              </div>
            )}
          </div>
          {answer.type === "answer" && showDiff === true && (
            <>
              <h5
                className={styles2.resendEmailButton}
                style={{ margin: "1rem 0px" }}
                onClick={toggleIsShow}
              >
                {isShow ? "Hide" : "View"} Response changes
              </h5>
              {isShow && (
                <ResponseDifference
                  title="Difference between last two response."
                  oldText={decodeURI(oldText.answerText)}
                  newText={decodeURI(answer.answerText)}
                  toggleModal={toggleIsShow}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default QuestionResponse;
