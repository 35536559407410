import React from "react";
import { useFormikContext } from "formik";
import styles from "./formButton.module.css";

function FormButton({
  Loadingbuttontext,
  buttonText,
  onClick,
  size = 2.2,
  isBackground = true,
  submitButton = false,
  borderRadius = 8,
  ...otherProps
}) {
  const { handleSubmit } = useFormikContext();

  const handleClick = (e) => {
    if (submitButton) {
      handleSubmit(e);
    } else {
      onClick();
    }
  };

  if (Loadingbuttontext === undefined) {
    return (
      <button
        style={{ fontSize: `${size}rem`, borderRadius: `${borderRadius}px` }}
        className={`${styles.button} ${
          isBackground ? styles.withBackground : styles.noBackground
        }`}
        onClick={handleClick}
        {...otherProps}
        // disabled={submitButton ? !isValid : false}
      >
        {buttonText}
      </button>
    );
  } else {
    return (
      <button
        style={{ fontSize: `${size}rem` }}
        className={`${styles.button} ${
          isBackground ? styles.withBackground : styles.noBackground
        }`}
        onClick={handleClick}
        {...otherProps}
        // disabled={submitButton ? !isValid : false}
      >
        <i className="fa fa-refresh fa-spin" style={{ marginRight: "10px" }} />
        {Loadingbuttontext}
      </button>
    );
  }
}

export default FormButton;
