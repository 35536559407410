import React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { BiEditAlt } from "react-icons/bi";
import { Modal, ViewCardDueDate } from ".";
import { updateQuestionnaire } from "../../../services/questionnaireService";
import { editQuestionnaire } from "../../../stores/QuestionnaireReducer";
import useVisible from "../../../hooks/useVisible";
import styles from "./viewQuestionCard.module.css";

function ViewQuestionCard({ questionnaire }) {
  const [isModalVisible, toggleModal] = useVisible(false);
  const dispatch = useDispatch();

  const handleEditQuestionnaireSubmission = async (editedQuestionnaire) => {
    const { questionnaireName, importance, dueDate } = editedQuestionnaire;
    try {
      await updateQuestionnaire(editedQuestionnaire._id, {
        questionnaireName,
        importance,
        dueDate,
      });

      dispatch(editQuestionnaire(editedQuestionnaire));
      toast.success("Questionnaire is updated!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // console.log(ex.response.data);
        toast.error("Not able update Questionnaire!");
      }
    }
  };

  return (
    <>
      <Modal
        title="Edit Questionnaire"
        showModal={isModalVisible}
        toggleModal={toggleModal}
        initialValues={questionnaire}
        addDraftQuestion={handleEditQuestionnaireSubmission}
        submitButtonText="save"
      />
      <div className={styles.questionCardContainer}>
        <div className={styles.questionTextContainer}>
          <div>
            <div className={styles.questionHeadingContainer}>
              <h2 className={styles.heading2}>
                {questionnaire?.questionnaireName}
              </h2>
              <span onClick={toggleModal}>
                <BiEditAlt size={30} /> Edit
              </span>
            </div>
            <h5 className="text-normal--bold text-normal2">Importance</h5>
            <h5 className="heading5">{questionnaire?.importance}</h5>
          </div>
          <ViewCardDueDate date={questionnaire?.dueDate} />
        </div>
        <div className={styles.questionTextContainer}>
          <div className={styles.bottomTextContainer}>
            <h4 className="heading4">
              {questionnaire?.sections?.length} Sections Added
            </h4>
            <h4 className="heading4">
              {questionnaire.totalQuestions} Questions Added
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewQuestionCard;
