import React from "react";
import { AssignDueDate, AssignStatus, ClickToAssign } from ".";
import { statusList } from "../../../components";
import styles from "./assignSectionQuestion.module.css";

function AssignSectionQuestion({ question, style, onClickToAssign }) {
  const { status, questionName, assignedUser } = question;

  return (
    <div className={styles.assignSectionQuestionContainer} style={style}>
      <div className={styles.questionTitlesContainer}>
        <h5 className="heading5">{questionName}</h5>
      </div>
      <div className={styles.summaryContainer}>
        <AssignStatus status={status} statusText={statusList[status]} />
        <AssignDueDate dueDate={"Tue, 26 Dec"} />
        <ClickToAssign
          title="assign to"
          assignEmail={assignedUser}
          onSubmit={onClickToAssign}
          questionStatus={question.status}
        />
      </div>
    </div>
  );
}

export default AssignSectionQuestion;
