import React, { useRef } from "react";
import * as Yup from "yup";
import { Form, FormButton, FormInput } from "../../components/Forms";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useVisible from "../../hooks/useVisible";
import trimObjectValues from "../Dashboard/utils/trimObject";
import styles from "../Dashboard/components/editSectionTitle.module.css";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().min(5).label("Email").email().trim(),
});

function AssignToSomeone({ isShow, onSubmit, toggleShow, email }) {
  const ref = useRef(null);
  const [isError, toggleIsError] = useVisible(false);
  useOnClickOutside(ref, () => {
    if (isShow) {
      toggleShow();
    }

    if (isError) toggleIsError();
  });

  if (isShow === false) return null;

  return (
    <div className={styles.textContainer} ref={ref}>
      <Form
        initialValues={{ email: "" }}
        onSubmit={(data) => {
          if (email === data.email) {
            toggleIsError();
            return;
          }
          data = trimObjectValues(data);
          onSubmit(data);
        }}
        validationSchema={validationSchema}
      >
        <div className={styles.assignForm}>
          <div className={styles.groupForm}>
            <FormInput
              label="Enter a colleague’s email address"
              name="email"
              type="text"
              showError={false}
            />
            <FormButton
              buttonText="save"
              type="submit"
              submitButton={true}
              size={1.8}
              borderRadius={3}
            />
          </div>
          {isError && (
            <p className={styles.errorMessage}>
              You can't assign to same email
            </p>
          )}
        </div>
      </Form>
    </div>
  );
}

export default AssignToSomeone;
