import React from "react";
import { useSelector } from "react-redux";
import { dayDateMonthFormat } from "../utils/date-formats";
import styles from "./clickToAssign.module.css";

function AssignDueDate() {
  const dueDate = useSelector(
    (state) => state.entities.questionnaire.questionnaire.dueDate
  );

  return (
    <div>
      <p className={styles.title}>Due date</p>
      <p className={`${styles.subtitle} text-sm2`}>
        {dayDateMonthFormat(dueDate)}
      </p>
    </div>
  );
}

export default AssignDueDate;
