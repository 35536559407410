import React from "react";
import toast from "react-hot-toast";
import { BsPlusSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Button, Loader } from "../../../components";
import { DraftQuestionnairs } from "../components/DraftQuestions/";
import { Header, Modal, EmptyState } from "../components";
import { createQuestionnaire } from "../../../services/questionnaireService";
import {
  addQuestionnaire,
  getDraftQuestionnaires,
  getActiveQuestionnaires,
  getCompletedQuestionnaires,
} from "../../../stores/questionnairesReducer";
import useVisible from "../../../hooks/useVisible";

function Home() {
  const [isModalVisible, toggleModal] = useVisible(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { loading, error } = state?.entities?.questionnaires;

  const draftQuestionnaires = useSelector(getDraftQuestionnaires) || [];
  const activeQuestionnaires = useSelector(getActiveQuestionnaires) || [];
  const completedQuestionnaires = useSelector(getCompletedQuestionnaires) || [];

  const addDraftQuestion = async (newQuestion) => {
    try {
      const response = await createQuestionnaire(newQuestion);

      dispatch(
        addQuestionnaire({
          ...newQuestion,
          _id: response.data.id,
          status: "draft",
        })
      );

      toast.success("Questionnaire is created!");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("Not able to create Questionnaire!");
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        <h3 className="heading3 text-center">
          Not able to fetch information from the server!
        </h3>
      </div>
    );
  }

  return (
    <>
      <Header
        title="Welcome to Gather."
        subtitle="Click on a questionnaire below to see more or create a new one."
      >
        <Button
          buttonText="NEW QUESTIONNAIRE"
          Icon={BsPlusSquare}
          onClick={toggleModal}
          size={1.8}
        />
      </Header>
      <Modal
        title="Create New Questionnaire"
        showModal={isModalVisible}
        toggleModal={toggleModal}
        addDraftQuestion={addDraftQuestion}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {draftQuestionnaires.length === 0 &&
          activeQuestionnaires.length === 0 &&
          completedQuestionnaires.length === 0 ? (
            <>
              <EmptyState onClick={toggleModal} />
            </>
          ) : (
            <>
              <DraftQuestionnairs
                questionnaire={draftQuestionnaires}
                title="Draft Questionnaires"
              />

              <DraftQuestionnairs
                questionnaire={activeQuestionnaires}
                title="Active Questionnaires"
              />

              <DraftQuestionnairs
                questionnaire={completedQuestionnaires}
                title="Completed Questionnaires"
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Home;
