import React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  ConfirmationModel,
  ClickToAssign,
  Modal,
  QuestionnaireButton,
  ViewCardDueDate,
} from ".";
import useVisible from "../../../hooks/useVisible";
import {
  editQuestionnaire,
  assignToAllQuestionnaireQuestionsInStore,
  removeAssignedUserFromStore,
} from "../../../stores/QuestionnaireReducer";
import { updateQuestionnaire } from "../../../services/questionnaireService";
import { assignToQuestionnaireAllQuestions } from "../../../services/assignQuestionService";
import { removeAssignedUserAPICall } from "../../../services/removeAssignedUserService";
import modalText from "../../modalText";
import styles from "./viewQuestionCard.module.css";
import styles2 from "./assignQuestionnirCard.module.css";

function AssignQuestionnirCard({ questionnaire }) {
  const [isModalVisible, toggleModal] = useVisible(false);
  const [isReadyToRemoveAssignedUser, toggleIsReadyToRemoveAssignedUser] =
    useVisible(false);
  const dispatch = useDispatch();

  const handleRemoveAssignedUserForSection = async () => {
    const payload = {
      questionnaireId: questionnaire._id,
    };
    try {
      await removeAssignedUserAPICall(payload);
      dispatch(removeAssignedUserFromStore(payload));
      toggleIsReadyToRemoveAssignedUser();
      toast.success("Assign email is removed from questionnaire!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // history.push("/not-found");
        toast.error("Not able to assign email!");
      }
    }
  };

  const handleEditQuestionnaireSubmission = async (editedQuestionnaire) => {
    const { questionnaireName, importance, dueDate } = editedQuestionnaire;
    try {
      await updateQuestionnaire(editedQuestionnaire._id, {
        questionnaireName,
        importance,
        dueDate,
      });

      dispatch(editQuestionnaire(editedQuestionnaire));
      toast.success("Questionnaire is updated!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able update Questionnaire!");
      }
    }
  };

  const handleClickToAssignSubmit = async (newEmail) => {
    const payload = { emailAssignToQuestion: newEmail };
    try {
      await assignToQuestionnaireAllQuestions(questionnaire._id, payload);

      dispatch(assignToAllQuestionnaireQuestionsInStore(payload));
      toast.success("Successfully Assigned To All Questionnaire Questions!");
      // return data;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // history.push("/not-found");
        toast.error("Not able to Assigned To All Questionnaire Questions!");
      }
    }
  };

  return (
    <>
      <Modal
        title="Edit Questionnaire"
        initialValues={questionnaire}
        showModal={isModalVisible}
        toggleModal={toggleModal}
        addDraftQuestion={handleEditQuestionnaireSubmission}
        submitButtonText="save"
      />
      <div className={styles.questionCardContainer}>
        <div className={styles.questionTextContainer}>
          <div>
            <div className={styles.questionHeadingContainer}>
              <h2 className={styles.heading2}>
                {questionnaire.questionnaireName}
              </h2>
            </div>
            <h5 className="text-normal--bold text-normal2">Importance</h5>
            <h5 className="heading5">{questionnaire.importance}</h5>
          </div>
          <ViewCardDueDate date={questionnaire.dueDate} />
        </div>
        <div className={styles.questionTextContainer2}>
          {/* <div className={styles.bottomTextContainer}>
                    <h4>0 Sections Added</h4>
                    <h4>0 Questions Added</h4>
                </div> */}
          <h6
            className={`${styles2.bottomText} text-sm1`}
            onClick={toggleModal}
          >
            EDIT QUESTIONNAIRE
          </h6>
          <div className={`${styles2.secondHeaderContainer}`}>
            <ClickToAssign
              title="assign all questions to"
              subtitle="Click to assign"
              assignEmail={questionnaire.assignedUser || ""}
              onSubmit={handleClickToAssignSubmit}
              isReadyToRemove={isReadyToRemoveAssignedUser}
            />
            {questionnaire.assignedUser && (
              <QuestionnaireButton
                buttonText="remove"
                buttonType="delete"
                onClick={toggleIsReadyToRemoveAssignedUser}
                style={{ marginLeft: "-10rem" }}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmationModel
        title={modalText.removeAssign.title}
        subtitle={modalText.removeAssign.subtitle}
        showModal={isReadyToRemoveAssignedUser}
        toggleModal={toggleIsReadyToRemoveAssignedUser}
        onConfirm={handleRemoveAssignedUserForSection}
      />
    </>
  );
}

export default AssignQuestionnirCard;
