import React from "react";
import * as Yup from "yup";
import { Portal, RadioButtonGroup } from ".";
import { Button } from "../../../components";
import { Card } from "../../../components/UI";
import { Form, FormButton } from "../../../components/Forms";
import styles from "./modal.module.css";

const radioOptions = [
  { key: "Delete Question & Section", value: "1" },
  { key: "Delete only Section", value: "2" },
];

const validationSchema = Yup.object({
  option: Yup.string().required("Required"),
});

function SectionConfirmationModel({
  showModal,
  toggleModal,
  title,
  subtitle,
  onConfirm,
}) {
  if (!showModal) return null;

  return (
    <Portal toggleModal={toggleModal}>
      <div className={styles.confirmationModal}>
        <Card>
          <div className={styles.modalContainer}>
            {title && <h3 className="bold-heading">{title}</h3>}
            {subtitle && <p className="text-normal">{subtitle}</p>}
          </div>

          <Form
            initialValues={{ option: "1" }}
            onSubmit={onConfirm}
            validationSchema={validationSchema}
          >
            <RadioButtonGroup name="option" options={radioOptions} />
            <div className={styles.buttonsContainer}>
              <Button
                buttonText="CANCEL"
                isBackground={false}
                onClick={toggleModal}
              />
              <FormButton
                buttonText="confirm"
                type="submit"
                submitButton={true}
              />
            </div>
          </Form>
        </Card>
      </div>
    </Portal>
  );
}

export default SectionConfirmationModel;
