import React from "react";
import { useDispatch } from "react-redux";
import { TiMediaPauseOutline } from "react-icons/ti";
import {
  ConfirmationModel,
  Header,
  PauseAndEditButton,
  ResponseQuestionnaireCard,
  ResponseSection,
  ResponseSectionQuestion,
} from "../components";
import { pauseAndEditQuestionnaire } from "../../../stores/QuestionnaireReducer";
import { pauseQuestionnaire } from "../../../services/pauseQuestionnaireService";
import useVisible from "../../../hooks/useVisible";
import modalText from "../../modalText";
import styles from "../components/newSection.module.css";

function ResponsesOfQuestionnaire({ questionnaire }) {
  const [isConfirmVisible, toggleConfirm] = useVisible(false);
  const dispatch = useDispatch();

  const pauseQuestionnaireHandler = async () => {
    try {
      await pauseQuestionnaire(questionnaire._id);

      toggleConfirm();
      dispatch(pauseAndEditQuestionnaire());
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }
    }
  };

  const sectionList = questionnaire.sections;
  const outerQuestions = questionnaire.questions;

  return (
    <div>
      <Header
        title="Gathering Responses"
        subtitle="This is an active questionnaire and responses are being gathered from the assignees."
      >
        {questionnaire.status !== "completed" && (
          <PauseAndEditButton
            buttonText="pause and edit"
            Icon={TiMediaPauseOutline}
            onClick={toggleConfirm}
            size={1.8}
          />
        )}
      </Header>

      <ResponseQuestionnaireCard questionnaire={questionnaire} />
      {sectionList.map((section, sectionIndex) => (
        <ResponseSection
          section={section}
          key={sectionIndex}
          index={sectionIndex}
          questionnaireId={questionnaire._id}
          dueDate={questionnaire.dueDate}
        />
      ))}

      {outerQuestions.map((question, index) => (
        <div className={styles.singleQuestionCardContainer} key={index}>
          <ResponseSectionQuestion
            question={question}
            style={{ borderColor: "transparent" }}
            questionnaireId={questionnaire._id}
            dueDate={questionnaire.dueDate}
          />
        </div>
      ))}
      <ConfirmationModel
        title={modalText.pauseQuestionnaire.title}
        subtitle={modalText.pauseQuestionnaire.subtitle}
        showModal={isConfirmVisible}
        toggleModal={toggleConfirm}
        onConfirm={pauseQuestionnaireHandler}
      />
    </div>
  );
}

export default ResponsesOfQuestionnaire;
