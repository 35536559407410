import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  questionnaireList: [],
  error: "",
};

const assignQuestionnaireSlice = createSlice({
  name: "assignQuestionnaires",
  initialState: initialState,
  reducers: {
    setAllQuestionnaires: (state, action) => {
      state.loading = false;
      state.error = "";
      state.questionnaireList = action.payload.questionnaireList;
    },
    deleteQuestionnaireFromStore: (state, action) => {
      const filteredQuestionniresList = state.questionnairesList.filter(
        (questionnaire) => questionnaire._id !== action.payload._id
      );
      state.questionnaireList = filteredQuestionniresList;
    },
    setErrorForQuestionnaire: (state, action) => {
      state.loading = false;
      state.error = "Not able to fetch questionnaires info!";
      return state;
    },
  },
});

export const {
  setAllQuestionnaires,
  setErrorForQuestionnaire,
  deleteQuestionnaireFromStore,
} = assignQuestionnaireSlice.actions;
export default assignQuestionnaireSlice.reducer;
