import http from "./httpService";

const apiEndpoint = http.apiUrl + "/assign/question";

function assignToAllQuestionnaireQuestionURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function assignToQuestionnaireAllQuestions(questionnaireId, payload) {
  return http.put(
    assignToAllQuestionnaireQuestionURl(questionnaireId),
    payload
  );
}

export function assignToIndividualOuterQuestion(questionId, payload) {
  return http.patch(assignToAllQuestionnaireQuestionURl(questionId), payload);
}
