import React from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import CardDueDate from "../CardDueDate";
import CardStatus from "../CardStatus";
import Modal from "../Modal";
import QuestionnairesCardButtons from "../QuestionnairesCardButtons";
import styles from "./draftQuestionItem.module.css";
import { useHistory } from "react-router-dom";
import { deleteQuestinnaire } from "../../../../services/questionnaireService";
import { reuseQuestionnaireAPI } from "../../../../services/reuseQuestionnaireService";
import {
  addQuestionnaire,
  deleteQuestionnaireFromStore,
} from "../../../../stores/questionnairesReducer";
import useVisible from "../../../../hooks/useVisible";
import ConfirmationModel from "../ConfirmationModel";
import modalText from "../../../modalText";

// Draft questionnair components which show the draft list questionnair
function DraftQuestionItem({ draftQuestion }) {
  const [isModalVisible, toggleModal] = useVisible(false);
  const [isConfirmVisible, toggleConfirm] = useVisible(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDeleteClick = async () => {
    try {
      await deleteQuestinnaire(draftQuestion._id);
      dispatch(deleteQuestionnaireFromStore(draftQuestion));
      toggleConfirm();
      toast.success("Questionnaire is deleted!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("This questionnaire already been deleted.");
      }
    }
  };

  const handleReuseClick = async (reuseQuestionnaire) => {
    try {
      const response = await reuseQuestionnaireAPI({
        _id: reuseQuestionnaire._id,
        questionnaireName: reuseQuestionnaire.questionnaireName,
        dueDate: reuseQuestionnaire.dueDate,
        importance: reuseQuestionnaire.importance,
      });

      toggleModal();
      dispatch(
        addQuestionnaire({
          ...reuseQuestionnaire,
          _id: response.data.id,
          status: "draft",
        })
      );
      toast.success("Questionnaire is reused!");
    } catch (ex) {
      console.log(ex);
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("This questionnaire already been reused.");
      }
    }
  };

  const handleViewClick = () => {
    history.push(`/dashboard/details/${draftQuestion._id}`);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.draftQuestionContainer}>
          <h2 className={styles.heading2}>{draftQuestion.questionnaireName}</h2>
          {draftQuestion.status !== "completed" && (
            <div className={styles.cardContainer}>
              <CardDueDate date={draftQuestion.dueDate} />
              <CardStatus
                status={draftQuestion.status}
                totalQuestion={draftQuestion.totalQuestions}
                complateQuestion={draftQuestion.answeredQuestions}
              />
            </div>
          )}
        </div>
        {draftQuestion.status === "completed" && (
          <div className={styles.completedDateContainer}>
            <p className="text-normal">
              Completed on{" "}
              <b>{moment(draftQuestion.completedDate).format("Do MMM YYYY")}</b>
            </p>
          </div>
        )}
        <QuestionnairesCardButtons
          onDeleteClick={toggleConfirm}
          onReuseClick={toggleModal}
          onViewClick={handleViewClick}
          questionnaireStatus={draftQuestion.status}
        />
      </div>
      <Modal
        title="Reuse Questionnaire"
        showModal={isModalVisible}
        toggleModal={toggleModal}
        initialValues={draftQuestion}
        addDraftQuestion={handleReuseClick}
      />
      <ConfirmationModel
        title={modalText.questionnaireDelete.title}
        subtitle={modalText.questionnaireDelete.subtitle}
        showModal={isConfirmVisible}
        toggleModal={toggleConfirm}
        onConfirm={handleDeleteClick}
      />
    </>
  );
}

export default DraftQuestionItem;
