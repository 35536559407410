import http from "./httpService";

const apiEndpoint = http.apiUrl + "/question";

function questionURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function createQuestion(question) {
  return http.post(apiEndpoint, question);
}

export function updateQuestion(questionId, question) {
  return http.patch(questionURl(questionId), question);
}

export function deleteQuestion(questionId, question) {
  return http.delete(questionURl(questionId), {
    data: question,
  });
}
