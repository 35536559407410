import { combineReducers } from "redux";
import questionnairesReducer from "./questionnairesReducer";
import questionnaire from "./QuestionnaireReducer";
import userReducer from "./userReducer";
import responderReducer from "./responderReducer";
import assignQuestionnaireReducer from "./responderQuestionnaireReducer";

export default combineReducers({
  questionnaires: questionnairesReducer,
  assignQuestionnaires: assignQuestionnaireReducer,
  user: userReducer,
  questionnaire: questionnaire,
  responder: responderReducer,
});
