import React from "react";
import toast from "react-hot-toast";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  AssignDueDate,
  AssignStatus,
  ConfirmationModel,
  MoreInfoForm,
  ResponderAssignEmail,
} from ".";
import { Button, statusList } from "../../../components";
import styles from "./assignSectionQuestion.module.css";
import { acceptResponseAPI } from "../../../services/acceptResponseService";
import { getFileFromAPI } from "../../../services/downloadService";
import { requestMoreInfoAPI } from "../../../services/requestMoreInfoService";
import {
  acceptResponseOfQuestion,
  requestMoreInfoForResponse,
} from "../../../stores/QuestionnaireReducer";
import useVisible from "../../../hooks/useVisible";
import modalText from "../../modalText";
import QuestionResponse from "./QuestionResponse";

const sortByDate = (arr) => {
  const sorter = (a, b) => {
    return (
      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );
  };
  return arr.sort(sorter);
};

function ResponseSectionQuestion({
  question,
  style,
  questionnaireId,
  sectionId,
  dueDate,
}) {
  const dispatch = useDispatch();
  const [isShow, toggleIsShow] = useVisible(false);
  const [isReadyToAskMoreInfo, toggleReadyToAskMoreInfo] = useVisible(false);
  const [isReadyToAcceptResponse, toggleIsReadyToAcceptResponse] =
    useVisible(false);
  const { status, questionName, assignedUser, assign, answer } = question;

  const handleAcceptResponse = async () => {
    const acceptResponsePayload = {
      questionId: question._id,
      sectionId: sectionId,
      questionnaireId: questionnaireId,
    };
    try {
      await acceptResponseAPI(acceptResponsePayload);

      dispatch(acceptResponseOfQuestion(acceptResponsePayload));
      toggleIsShow();
      toggleIsReadyToAcceptResponse();
      window.location.reload();
      toast.success("Successfully accepted the response!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.success("Not able to accepted the response!");
      }
    }
  };

  const handleMoreInfo = async ({ additionalInfo }) => {
    if (moment().isAfter(dueDate)) {
      toast.error(
        "Your questionnaire has expired. Please update the expiration date to continue gathering the response."
      );
    } else {
      const requestMoreInfoPayload = {
        questionId: question._id,
        sectionId: sectionId,
        questionnaireId: questionnaireId,
        additionalInfo: additionalInfo,
      };

      try {
        await requestMoreInfoAPI(requestMoreInfoPayload);

        dispatch(requestMoreInfoForResponse(requestMoreInfoPayload));
        toggleIsShow();
        // return data;
        toast.success("Email Send for asking for more info!");
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status <= 409
        ) {
          toast.error("Not able to Send email for asking for more info!");
        }
      }
    }
  };

  const handleDownloadFile = async (filePayload) => {
    try {
      const response = await getFileFromAPI(filePayload);
      const blob = await response.data;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePayload?.orignalFileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("File not found!");
      }
    }
  };

  const answerListLength = answer.length - 1;

  return (
    <>
      <ConfirmationModel
        title={modalText.acceptResponse.title}
        subtitle={modalText.acceptResponse.subtitle}
        showModal={isReadyToAcceptResponse}
        toggleModal={toggleIsReadyToAcceptResponse}
        onConfirm={handleAcceptResponse}
      />

      <div
        className={`${styles.assignSectionQuestionContainer} ${
          isShow ? styles.open : ""
        }`}
        style={style}
      >
        <div className={styles.questionTitlesContainer}>
          <h5 className="heading5">
            {questionName}{" "}
            {status === "readyForReview" || status === "completed" ? (
              <b onClick={toggleIsShow} className={styles.showResponseButton}>
                {isShow ? "Hide" : "Show"} Response
              </b>
            ) : null}
          </h5>
        </div>
        <div className={styles.summaryContainer}>
          <AssignStatus status={status} statusText={statusList[status]} />
          <AssignDueDate />
          <ResponderAssignEmail
            assignEmail={assignedUser}
            assignList={assign}
          />
        </div>
      </div>
      {isShow && (
        <>
          <div className={styles.responseContainer}>
            {question.status === "readyForReview" ? (
              <>
                <QuestionResponseList
                  answers={sortByDate([...answer])}
                  onDownloadClick={handleDownloadFile}
                />
              </>
            ) : (
              <QuestionResponse
                answer={answer[answerListLength]}
                onDownloadClick={handleDownloadFile}
                showDiff={false}
              />
            )}
          </div>
          {isReadyToAskMoreInfo && status === "readyForReview" ? (
            <MoreInfoForm
              initialValues={{ additionalInfo: "" }}
              onCancel={toggleReadyToAskMoreInfo}
              onSubmit={handleMoreInfo}
            />
          ) : (
            <>
              {status === "readyForReview" ? (
                <>
                  <div className={styles.acceptOrMoreInfoContainer}>
                    <div className={styles.containerText}>
                      <p className="text-sm2" style={{ color: "var(--black)" }}>
                        Accept the response if you’re happy or request for more
                        information.
                      </p>
                    </div>
                    <div className={styles.buttonsContainer}>
                      <Button
                        buttonText={"REQUEST MORE INFO"}
                        isBackground={false}
                        size={1.8}
                        onClick={toggleReadyToAskMoreInfo}
                      />

                      <Button
                        buttonText={"ACCEPT RESPONSE "}
                        size={1.8}
                        onClick={toggleIsReadyToAcceptResponse}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ResponseSectionQuestion;

const QuestionResponseList = ({ answers, onDownloadClick }) => {
  return (
    <>
      {answers.map((answerItem, index) => (
        <QuestionResponse
          answer={answerItem}
          key={answerItem._id}
          onDownloadClick={onDownloadClick}
          showDiff={answers.length - 1 === index ? false : true}
          oldText={answers[index + 2] ? answers[index + 2] : { answerText: "" }}
        />
      ))}
    </>
  );
};
