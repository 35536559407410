import React from "react";
import DraftQuestionItem from "./DraftQuestionItem";
import styles from "./draftQuestionnairs.module.css";

function DraftQuestionnairs({ questionnaire = [], title }) {
  if (questionnaire.length === 0) return null;

  return (
    <section className={styles.container}>
      {title && <h4 className="heading4">{title}</h4>}

      <div className={styles.questionnaireList}>
        {questionnaire.map((item, index) => (
          <DraftQuestionItem draftQuestion={item} key={index} />
        ))}
      </div>
    </section>
  );
}

export default DraftQuestionnairs;
