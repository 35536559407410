import axios from "axios";
import toast from "react-hot-toast";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 409 &&
    error.response.status <= 500;

  if (expectedError) {
    // logger.log(error);
    toast.error("An unexpected error occurrred.");
    // alert("An unexpected error occurrred.");
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function setResonderJwt(jwt) {
  axios.defaults.headers.common["x-responder-token"] = jwt;
}

const apiUrl = "https://jhavtech-gather-backend.herokuapp.com/api";
// const apiUrl = "http://localhost:8080/api";

const httpMethodsObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
  setResonderJwt,
  apiUrl,
};

export default httpMethodsObject;
