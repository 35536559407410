import React from "react";
import { useField, ErrorMessage } from "formik";
import styles from "./formField.module.css";

function FormField({ title, subtitle, element, ...otherProps }) {
  const [field, meta] = useField(otherProps);
  let ele;

  if (element === "input") {
    ele = (
      <input
        className={`${styles.formField} ${
          meta.touched && meta.error ? styles.error : styles.success
        }`}
        {...otherProps}
        {...field}
      />
    );
  } else if (element === "textarea") {
    ele = (
      <textarea
        className={`${styles.formField} ${
          meta.touched && meta.error ? styles.error : styles.success
        }`}
        {...otherProps}
        {...field}
      />
    );
  }

  return (
    <div className={styles.formGroup}>
      {title && <h4 className="heading4">{title}</h4>}
      {subtitle && <p className="text-sm3">{subtitle}</p>}
      {ele && ele}
      <ErrorMessage
        component="div"
        name={field.name}
        className={styles.errorMessage}
      />
    </div>
  );
}

export default FormField;
