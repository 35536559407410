import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { Navbar } from "./components";
import { Home, DetailQuestion, AssignQuestionnair, Responder } from "./Screens";
import { setUser } from "../../stores/userReducer";
import { fetchQuestionnaires } from "../../stores/questionnairesReducer";
import { Loader } from "../../components";
import styles from "./global.module.css";

function Dashboard({ user }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUser(user));
    dispatch(fetchQuestionnaires());
  }, [dispatch, user]);

  if (!user) {
    return <Loader />;
  }

  return (
    <div>
      <Navbar email={user?.email} />
      <div className={styles.container}>
        <Switch>
          {user.isAdmin ? (
            <>
              <Route
                path="/dashboard/details/:id"
                component={DetailQuestion}
                exact
              />
              <Route
                path="/dashboard/:id/assign"
                component={AssignQuestionnair}
                exact
              />
              <Route path="/dashboard" component={Home} exact />
            </>
          ) : (
            <Route path="/dashboard" component={Responder} exact />
          )}
          <Redirect to="/not-found" />
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
