import React from "react";
import { ModalForm, Portal } from ".";
import { Card } from "../../../components/UI";
import styles from "./modal.module.css";

// seting the inital due date to 7 day after from the current date
const currentDay = new Date();
currentDay.setDate(currentDay.getDate() + 7);

function Modal({
  showModal,
  toggleModal,
  title,
  addDraftQuestion,
  initialValues = {
    questionnaireName: "",
    importance: "",
    dueDate: currentDay,
  },
  submitButtonText,
}) {
  if (!showModal) return null;

  return (
    <Portal toggleModal={toggleModal}>
      <div className={styles.modal}>
        <Card>
          <div className={styles.modalContainer}>
            <h1 className={styles.heading1}>{title}</h1>
            <ModalForm
              initialValues={initialValues}
              toggleModal={toggleModal}
              addDraftQuestion={addDraftQuestion}
              submitButtonText={submitButtonText || "CREATE DRAFT"}
            />
          </div>
        </Card>
      </div>
    </Portal>
  );
}

export default Modal;
