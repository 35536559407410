import http from "./httpService";

const apiEndpoint = http.apiUrl + "/users";

export function register(user) {
  return http.post(apiEndpoint, {
    fullName: user.fullName,
    email: user.email,
    designation: user.designation,
  });
}
