import React from "react";
import styles from "./cardContainer.module.css";
import Logo from "../Logo";

function CardContainer({ children }) {
  return (
    <div className={styles.main}>
      <Logo />
      <div className={styles.container}>{children}</div>
    </div>
  );
}

export default CardContainer;
