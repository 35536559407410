import React from "react";
import { useHistory } from "react-router-dom";
import CardDueDate from "../CardDueDate";
import ViewButton from "../../../../components/ViewButton";
import useVisible from "../../../../hooks/useVisible";
import { getTokenForAssignQuestionnaire } from "../../../../services/responderQuestionnaireService";
import styles from "./draftQuestionItem.module.css";

function DraftQuestionItem({ draftQuestion }) {
  const history = useHistory();
  const [isLoading, toggleIsLoading] = useVisible(false);

  const handleViewClick = async () => {
    toggleIsLoading();
    try {
      const response = await getTokenForAssignQuestionnaire(draftQuestion._id);
      const data = await response.data;
      toggleIsLoading();
      history.push(`/email-invite/response/${data.token}/0`);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }
      toggleIsLoading();
    }
  };

  return (
    <>
      <div
        className={`${styles.draftQuestionContainer} ${styles.assignContainer}`}
      >
        <h2 className={styles.heading2}>{draftQuestion.questionnaireName}</h2>

        <div className={styles.cardContainer}>
          <CardDueDate date={draftQuestion.dueDate} />
          <ViewButton
            buttonText="View"
            onClick={handleViewClick}
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default DraftQuestionItem;
