import React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  ConfirmationModel,
  NewSectionQuestionForm,
  QuestionnaireButton,
} from ".";
import {
  deleteQuestion,
  updateQuestion,
} from "../../../services/questionService";
import {
  editQuestion,
  removeQuestion,
} from "../../../stores/QuestionnaireReducer";
import useVisible from "../../../hooks/useVisible";
import modalText from "../../modalText";
import styles from "./sectionQuestion.module.css";

function SectionQuestion({ question, otherData, hasBorder = false }) {
  const [isEditMode, toggleIsEditMode] = useVisible(false);
  const [isConfirmVisible, toggleConfirm] = useVisible(false);
  const dispatch = useDispatch();

  const handleEditClick = () => {
    toggleIsEditMode();
  };

  const handleCancelClick = () => {
    toggleIsEditMode();
  };

  const handleSubmitClick = async (editedQuestion) => {
    try {
      await updateQuestion(editedQuestion._id, {
        ...otherData,
        questionName: editedQuestion.questionName,
        instruction: editedQuestion.instruction,
        responseCharLimit: editedQuestion.responseCharLimit,
      });

      dispatch(
        editQuestion({
          ...otherData,
          question: {
            questionId: editedQuestion._id,
            questionName: editedQuestion.questionName,
            instruction: editedQuestion.instruction,
            responseCharLimit: editedQuestion.responseCharLimit,
          },
        })
      );
      toggleIsEditMode();

      toast.success("Successfully edited Question!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able to edit question!");
      }
    }
  };

  const handleDeleteClick = async () => {
    try {
      await deleteQuestion(question._id, otherData);

      dispatch(
        removeQuestion({
          ...otherData,
          question: {
            questionId: question._id,
          },
        })
      );
      toggleConfirm();
      toast.success("Successfully deleted Question!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able to delete question!");
      }
    }
  };

  return (
    <>
      {!isEditMode ? (
        <div
          className={styles.sectionQuestionContainer}
          style={{ border: hasBorder && "none" }}
        >
          <div className={styles.sectionTextContainer}>
            <h5 className="text-normal2">{question?.questionName}</h5>
            <p className="text-sm2">{question?.instruction}</p>
            <p className="text-sm2">
              <span className="text--bold">Res. Char Limit : </span>
              {question?.responseCharLimit}
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <QuestionnaireButton
              buttonText="delete"
              buttonType="delete"
              onClick={toggleConfirm}
            />
            <QuestionnaireButton
              buttonText="edit"
              buttonType="reuse"
              onClick={handleEditClick}
            />
          </div>
        </div>
      ) : (
        <NewSectionQuestionForm
          onCancel={handleCancelClick}
          onSubmit={handleSubmitClick}
          initialValues={question}
        />
      )}
      <ConfirmationModel
        title={modalText.questionDelete.title}
        subtitle={modalText.questionDelete.subtitle}
        showModal={isConfirmVisible}
        toggleModal={toggleConfirm}
        onConfirm={handleDeleteClick}
      />
    </>
  );
}

export default SectionQuestion;
