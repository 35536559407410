import React from "react";
// import parse from "html-react-parser";
import { Portal } from ".";
// import { Button } from "../../../components";
import { Card } from "../../../components/UI/";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import styles from "./modal.module.css";

function ResponseDifference({
  title = "",
  oldText = "",
  newText = "",
  toggleModal,
}) {
  const oldRef = React.useRef();
  const newRef = React.useRef();
  const [state, newState] = React.useState({ oldText: "", newText: "" });

  React.useEffect(() => {
    if (oldRef.current) {
      newState((prev) => ({ ...prev, oldText: oldRef.current.textContent }));
    }
    if (newRef.current) {
      newState((prev) => ({ ...prev, newText: newRef.current.textContent }));
    }
  }, []);

  const newStyles = {
    variables: {
      dark: {
        highlightBackground: "#fefed5",
        highlightGutterBackground: "#ffcd3c",
      },
    },
    // line: {
    //   padding: "10px 2px",
    //   "&:hover": {
    //     background: "#a26ea1",
    //   },
    // },
    contentText: {
      fontSize: "1.5rem",
      fontFamily: '"Montserrat", sans-serif',
    }, // style object
  };

  return (
    <Portal toggleModal={toggleModal}>
      <div
        className={`${styles.confirmationModal} ${styles.differeceResponseModel}`}
      >
        <Card>
          <div className={styles.modalContainer}>
            {title && (
              <h3 className="bold-heading" style={{ margin: "1rem 0px" }}>
                {title}
              </h3>
            )}
          </div>
          <p
            ref={oldRef}
            dangerouslySetInnerHTML={{
              __html: oldText,
            }}
            style={{ display: "none" }}
          ></p>
          <p
            ref={newRef}
            dangerouslySetInnerHTML={{
              __html: newText,
            }}
            style={{ display: "none" }}
          ></p>
          <ReactDiffViewer
            styles={newStyles}
            oldValue={state.oldText}
            newValue={state.newText}
            splitView={true}
            hideLineNumbers={true}
            compareMethod={DiffMethod.WORDS}
          />
        </Card>
      </div>
    </Portal>
  );
}

export default ResponseDifference;
