const modalText = {
  questionnaireDelete: {
    title: "Are you sure you want to delete this Questionnaire?",
    subtitle:
      "Note: Deleting the Questionnaire will delete all the data associated with it.",
  },
  sectionDelete: {
    title: "Are you sure you want to delete the section?",
    subtitle: "Please select from the options below to proceed:",
  },
  questionDelete: {
    title: "Are you sure you want to delete the question?",
    subtitle:
      "Note: Deleting the question will delete all the data associated with it.",
  },
  removeAssign: {
    title: "Are you sure want to remove the associated email?",
    subtitle:
      "Note: Removing this email will not affect any existing question assignment.",
  },
  gatherResponse: {
    title: "Do you want to proceed to Gather responses to Questionnaire?",
    subtitle:
      "Note: Emails will be sent to user based on the assignment of questions upon confirmation.",
  },
  pauseQuestionnaire: {
    title: "Do you want to pause responses to Questionnaire?",
    subtitle:
      "Note: Responders won't be able to respond or view the Questionnaire while its paused.",
  },
  submitResponse: {
    title: "Are you sure want to submit the response?",
    subtitle:
      "Note: You won't be able to make the changes to the response upon confirmation.",
  },
  acceptResponse: {
    title: "Are you sure want to accept the response?",
    subtitle:
      "Note: You won't be able to make the changes to the response upon confirmation.",
  },
  resendEmail: {
    title: "Resend Emails",
    subtitle:
      "Select the assignees's email address to resend the response collection email.",
  },
};

export default modalText;
