import React from "react";
import styles from "./emptyState.module.css";

function EmptyState() {
  return (
    <div className={styles.container}>
      <h3 className="text-sm1">
        Click on New Questionnaire to create your first questionnaire
      </h3>
    </div>
  );
}

export default EmptyState;
