import React from "react";
import { getDateMonthYearFormat } from "../utils/date-formats";
import styles from "./cardDueDate.module.css";

function CardDueDate({ date, status = false }) {
  if (status === true) return null;

  return (
    <div className={styles.cardContainer}>
      <p className={styles.heading}>DUE ON</p>
      <div className={styles.container}>
        <p className={styles.containerText}>{getDateMonthYearFormat(date)}</p>
      </div>
    </div>
  );
}

export default CardDueDate;
