import http from "./httpService";

const apiEndpoint = http.apiUrl + "/gather-response";

function gatherResponseURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function gatherResponseForQuestionnaire(questionnaireId) {
  return http.post(gatherResponseURl(questionnaireId), { questionnaireId });
}
