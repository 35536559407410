import React, { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FiCheck } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import useVisible from "../../../hooks/useVisible";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import styles from "./clickToAssign.module.css";
import trimObjectValues from "../utils/trimObject";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().min(6).max(50).email().label("Email").trim(),
});

function rightOrWrongIcon(touched, error) {
  let icon;
  let className;

  if (touched && error) {
    className = "error";
    icon = (
      <div className={styles.iconContainer}>
        <FiX color="white" className={`${styles.icon} ${styles[className]}`} />
      </div>
    );
  } else if (touched && !error) {
    className = "success";
    icon = (
      <div className={styles.iconContainer}>
        <FiCheck
          color="white"
          className={`${styles.icon} ${styles[className]}`}
        />
      </div>
    );
  }
  return icon;
}

function ClickToAssign({
  title,
  subtitle = "click to assign",
  assignEmail = "",
  onSubmit,
  questionStatus,
}) {
  const [isVisible, toggleVisible] = useVisible(false);
  const [isError, toggleIsError] = useVisible(false);
  const { values, touched, handleSubmit, handleBlur, setFieldValue, errors } =
    useFormik({
      initialValues: {
        email: assignEmail,
      },
      validationSchema: validationSchema,
      onSubmit: (data) => {
        if (data.email === assignEmail && questionStatus !== "declined") {
          toggleIsError();
          toggleVisible();
          return;
        }

        data = trimObjectValues(data);
        onSubmit(data.email);
        toggleVisible();
      },
    });
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    toggleVisible();
    setFieldValue("email", assignEmail);
  });

  useEffect(() => {
    setFieldValue("email", assignEmail);
  }, [assignEmail, setFieldValue]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        toggleIsError();
      }, 4000);
    }
  }, [isError, toggleIsError]);

  if (questionStatus === "completed") {
    return (
      <div
        className={`${styles.containerWithoutBorder} ${styles.formContainer}`}
      >
        <p className={styles.title}>{title}</p>
        <p
          className={`${styles.subtitle}  text-sm2`}
          title="Can't edit because question is completed!"
        >
          {assignEmail && assignEmail}
        </p>
        {assignEmail && (
          <ReactTooltip
            id={assignEmail}
            place="top"
            effect="solid"
            delayShow={500}
          >
            {assignEmail && assignEmail}
          </ReactTooltip>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={styles.formContainer}>
      <div
        className={` ${
          isVisible
            ? `${styles.containerWithBorder} ${
                touched.email && errors.email ? styles.error : ""
              }`
            : styles.containerWithoutBorder
        } formInsideContainer `}
      >
        <p className={styles.title}>{title}</p>
        {!isVisible ? (
          <>
            <p
              className={`${styles.subtitle} text-sm2`}
              onClick={toggleVisible}
              data-tip
              data-for={assignEmail ? assignEmail : ""}
            >
              {/* {!assignEmail ? subtitle : assignEmail.substring(0, 19)} */}
              {!assignEmail ? subtitle : assignEmail}
            </p>

            {assignEmail && (
              <ReactTooltip
                id={assignEmail}
                place="top"
                effect="solid"
                delayShow={500}
              >
                {assignEmail && assignEmail}
              </ReactTooltip>
            )}
          </>
        ) : (
          <>
            <p className={`${styles.subtitle} text-sm2`} ref={ref}>
              <input
                name="email"
                type="email"
                placeholder="Type email"
                className={styles.input}
                value={values.email}
                autoFocus
                onChange={(event) => {
                  setFieldValue("email", event.target.value.trim());
                }}
                onBlur={handleBlur}
              />
            </p>
            {rightOrWrongIcon(touched.email, errors.email)}
          </>
        )}
      </div>
      {/* {errors.email && <p className={styles.errorMessage}>{errors.email}</p>} */}
    </form>
  );
}

export default ClickToAssign;
