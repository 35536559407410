import HTMLReactParser from "html-react-parser";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { BiEditAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import QuestionForm from "./SameQuestionForm";
import { AssignStatus } from "../../Dashboard/components/";
import { assignQuestionToAnotherEmail } from "../../../services/assignToAnotherService";
import { responseToQuestionByAPI } from "../../../services/responseService";
import {
  answerToQuestion,
  removeQuestion,
  getCountNotSubmittedQuestion,
} from "../../../stores/responderReducer";
import styles from "./question.module.css";

const statusList = {
  open: "Viewed",
  click: "Clicked",
  notAssigned: "Not Assigned",
  viewed: "Viewed",
  delivered: "Delivered",
  readyForReview: "Ready for Review",
  awaitingInfo: "Awaiting Info",
  declined: "Declined",
  completed: "Completed",
  assigned: "Assigned",
};

function Question({
  question,
  token,
  nextQuestion,
  prevQuestion,
  setTryForResponse,
  toggleTryForResponse,
}) {
  const dispatch = useDispatch();
  const countNotSubmittedQuestion = useSelector(getCountNotSubmittedQuestion);
  const [isEditMode, setEditMode] = useState(false);
  // const [question, setQuestion] = useState(responseQuestion);

  if (!question) return null;

  const answerList = question.answer;
  const answerListLength = answerList.length;

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleAssignToAnother = async (anotherUser) => {
    try {
      await assignQuestionToAnotherEmail(
        {
          assignedUser: anotherUser.email,
          sectionId: question.sectionId,
          _id: question._id,
          isLast: countNotSubmittedQuestion === 1 ? true : false,
        },
        token
      );

      dispatch(removeQuestion({ questionId: question._id }));
      prevQuestion();
      toast.success(`Sucessfully Assigned to ${anotherUser.email}`);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error(`Not able to Assigned!`);
      }
    }
  };

  const handleSubmitResponse = async (response, otherFormObj, status) => {
    const form = new FormData();
    form.append("answer", encodeURI(response.answer));
    form.append("attachment", response.files);
    form.append("sectionId", question.sectionId);
    if (question.status === "awaitingInfo" && status === "in-progress") {
      form.append("status", "awaitingInfo");
    } else {
      form.append("status", status);
    }

    if (response.answer === "" && !response.originalFileName) {
      form.append("clear", "yes");
    }

    if (question.answer.length !== 0) {
      const answerLength = question.answer.length;
      const answerList = question.answer;
      const answer = answerList[answerLength - 1];
      if (
        answer.type === "answer" &&
        question.assignedUser === answer.assignedUser
      ) {
        form.append("answerId", answer?._id);
      }
    }

    try {
      toggleTryForResponse();
      const res = await responseToQuestionByAPI(question._id, form, token);
      const data = await res.data;
      otherFormObj.toggleDisableButtonWhileAutoSaving();

      if (status === "in-progress") toast.success("Auto saved your response!");
      else toast.success("Sucessfully response to Question!");

      dispatch(
        answerToQuestion({
          questionId: question._id,
          answer: data.answer,
          status: data.question.status,
        })
      );
      otherFormObj.resetForm();
      otherFormObj.toggleShowConfirmationMode();
      nextQuestion();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }
    }
  };

  const handleEditResponse = async (response, otherFormObj, status) => {
    const form = new FormData();
    form.append("answer", encodeURI(response.answer));
    form.append("attachment", response.files);
    form.append("sectionId", question.sectionId);

    form.append("status", status);

    if (question.answer.length !== 0) {
      const answerLength = question.answer.length;
      const answerList = question.answer;
      const answer = answerList[answerLength - 1];
      if (
        answer.type === "answer" &&
        question.assignedUser === answer.assignedUser
      ) {
        form.append("answerId", answer?._id);
      }
    }

    try {
      toggleTryForResponse();
      const res = await responseToQuestionByAPI(question._id, form, token);
      const data = await res.data;
      otherFormObj.toggleDisableButtonWhileAutoSaving();

      if (status === "in-progress") toast.success("Auto saved your response!");
      else toast.success("Sucessfully response to Question!");

      dispatch(
        answerToQuestion({
          questionId: question._id,
          answer: data.answer,
          status: data.question.status,
        })
      );

      const resetBoolean = otherFormObj.resetForm();

      if (resetBoolean) {
        return;
      }
      toggleEditMode();
      // otherFormObj.toggleShowConfirmationMode();
      nextQuestion();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }
    }
  };

  const handleClearResponse = async (otherFormObj, status) => {
    const form = new FormData();
    form.append("answer", "");
    form.append("attachment", "");
    form.append("sectionId", question.sectionId);
    form.append("clear", "yes");
    if (question.status === "awaitingInfo" && status === "in-progress") {
      form.append("status", "awaitingInfo");
    } else {
      form.append("status", status);
    }

    if (question.answer.length !== 0) {
      const answerLength = question.answer.length;
      const answerList = question.answer;
      const answer = answerList[answerLength - 1];
      if (
        answer.type === "answer" &&
        question.assignedUser === answer.assignedUser
      ) {
        form.append("answerId", answer?._id);
      }
    }

    try {
      toggleTryForResponse();
      const res = await responseToQuestionByAPI(question._id, form, token);
      const data = await res.data;
      toast.success("Cleared your response!");
      otherFormObj.toggleDisableButtonWhileAutoSaving();

      dispatch(
        answerToQuestion({
          questionId: question._id,
          answer: data.answer,
          status: data.question.status,
        })
      );
      otherFormObj.resetForm();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }
    }
  };

  const handleInitialValues = () => {
    const initialValue = { answer: "", files: null, questionId: question._id };
    if (question.status === "in-progress" && answerList.length > 0) {
      initialValue.answer = decodeURI(
        answerList[answerListLength - 1]?.answerText || ""
      );

      initialValue.originalFileName =
        answerList[answerListLength - 1]?.originalFileName;
    } else if (
      (question.status === "awaitingInfo" ||
        question.status === "readyForReview") &
      (answerList.length > 0)
    ) {
      // handled awaiting info
      if (
        answerList[answerListLength - 1].type === "answer" &&
        answerList[answerListLength - 1].assignedUser === question.assignedUser
      ) {
        initialValue.answer = decodeURI(
          answerList[answerListLength - 1]?.answerText || ""
        );

        initialValue.originalFileName =
          answerList[answerListLength - 1]?.originalFileName;
      }
    }

    return initialValue;
  };

  return (
    <div>
      <div>
        <div className={styles.container}>
          <h3 className="question-title heading3">{question.questionName}</h3>
          {question.status === "readyForReview" ? (
            <div className="status">
              <AssignStatus
                status={question.status}
                statusText={statusList[question.status]}
              />
              {isEditMode === false && (
                <span className={styles.editButtton} onClick={toggleEditMode}>
                  <BiEditAlt size={30} /> Edit
                </span>
              )}
            </div>
          ) : null}
        </div>

        <h5 className="heading5-black">{question.instruction}</h5>
      </div>

      {answerListLength > 1 ? (
        <>
          {answerList[answerListLength - 1].type === "additionalInfo" && (
            <>
              <h3 className="heading3">
                <b>Asking for More Info</b>
              </h3>
              <p className="text-sm1">
                {answerList[answerListLength - 1].answerText}
              </p>
            </>
          )}
          {answerList[answerListLength - 2].type === "additionalInfo" &&
            answerListLength >= 2 && (
              <>
                <h3 className="heading3">
                  <b>Asking for More Info</b>
                </h3>
                <p className="text-sm1">
                  {answerList[answerListLength - 2].answerText}
                </p>
              </>
            )}
        </>
      ) : null}

      {question.status === "click" ||
      question.status === "awaitingInfo" ||
      question.status === "in-progress" ? (
        <QuestionForm
          onAssign={handleAssignToAnother}
          onSubmit={handleSubmitResponse}
          onClear={handleClearResponse}
          initialValues={handleInitialValues()}
          setTryForResponse={setTryForResponse}
          toggleTryForResponse={toggleTryForResponse}
          email={question.assignedUser}
          charLimit={question.responseCharLimit}
        />
      ) : (
        <>
          {answerList && answerList[answerListLength - 1]?.type === "answer" && (
            <>
              {isEditMode === false ? (
                <>
                  <h5 className="heading5">
                    <b>Your Response - </b>
                  </h5>
                  <p className="text-sm1">
                    {HTMLReactParser(
                      decodeURI(answerList[answerListLength - 1].answerText)
                    )}
                  </p>
                </>
              ) : (
                <QuestionForm
                  isEditMode={isEditMode}
                  onAssign={handleAssignToAnother}
                  onSubmit={handleEditResponse}
                  onClear={handleClearResponse}
                  initialValues={handleInitialValues()}
                  setTryForResponse={setTryForResponse}
                  toggleTryForResponse={toggleTryForResponse}
                  email={question.assignedUser}
                  charLimit={question.responseCharLimit}
                  onCancel={() => {
                    setEditMode(false);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Question;
