import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import { Logo } from "../../components";
import ResponderAccept from "./ResponderAccept";
import AssignedAnswer from "./AssignedAnswer";
import { setResponderTokenData } from "../../stores/responderReducer";
import { getCurrentResponder } from "../../services/authService";
import styles from "./emailInvite.module.css";

function EmailInvite() {
  const { token } = useParams();
  const [responder, setResponder] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const currResponder = getCurrentResponder(token);
    setResponder(() => {
      return currResponder;
    });
    dispatch(setResponderTokenData({ responder: currResponder, token }));
  }, [setResponder, dispatch, token]);

  return (
    <>
      <Switch>
        <Route
          path="/email-invite/response/:token/:questionId"
          component={AssignedAnswer}
          exact
        />
        <Route
          path="/email-invite/:token"
          component={(props) => (
            <ResponderAccept {...props} responder={responder} />
          )}
          exact
        />
        <Redirect to="/not-found" />
      </Switch>

      <div className={styles.logoContainer}>
        <Logo />
      </div>
    </>
  );
}

export default EmailInvite;
