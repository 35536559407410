import React from "react";
import styles from "../../../components/button.module.css";

function PauseAndEditButton({
  buttonText,
  onClick,
  Icon,
  size = 2.2,
  borderRadius = 8,
  isBackground = true,
}) {
  return (
    <button
      className={`${styles.button} ${
        isBackground ? styles.pauseAndEditButton : styles.noBackground
      }`}
      onClick={onClick}
      style={{
        borderRadius: `${borderRadius}px`,
      }}
    >
      {Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}
      <span className={styles.buttonText} style={{ fontSize: `${size}rem` }}>
        {buttonText}
      </span>
    </button>
  );
}

export default PauseAndEditButton;
