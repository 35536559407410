import React from "react";
import * as Yup from "yup";
import { FormField } from ".";
import { Form, FormButton } from "../../../components/Forms";
import trimObjectValues from "../utils/trimObject";
import styles from "./newSectionQuestionForm.module.css";

const validationSchema = Yup.object().shape({
  questionName: Yup.string().required().min(5).label("Question").trim(),
  instruction: Yup.string().min(6).label("Instruction").trim(),
  responseCharLimit: Yup.number().min(0).label("Response character limit"),
});

function NewSectionQuestionForm({
  createQuestionMode,
  initialValues,
  onSubmit,
  onCancel,
  hasBorder = false,
}) {
  if (createQuestionMode === false) return null;

  return (
    <div className={styles.container} style={{ border: hasBorder && "none" }}>
      <Form
        initialValues={initialValues}
        onSubmit={(data, otherFormObj) => {
          data = trimObjectValues(data);
          onSubmit(data, otherFormObj);
        }}
        validationSchema={validationSchema}
      >
        <div className={styles.formContainer}>
          <FormField
            title="Add new question"
            subtitle="Enter a question as you would like the responder to see it."
            element="input"
            name="questionName"
            autoFocus={true}
          />
          <FormField
            title="Additional Instructions"
            subtitle="Provide any additional information that would  help the responder."
            element="textarea"
            name="instruction"
            row={10}
          />
          <FormField
            title="Response character limit"
            subtitle="Enter a question response character limit."
            element="input"
            name="responseCharLimit"
            type="number"
          />
          <div className={styles.buttonsContainer}>
            <FormButton
              buttonText="CANCEL"
              isBackground={false}
              onClick={() => onCancel()}
              type="button"
            />
            <FormButton buttonText="save" type="submit" submitButton={true} />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default NewSectionQuestionForm;
