import React from "react";
import * as Yup from "yup";
import { Portal, CheckBoxGroup } from ".";
import { Button } from "../../../components";
import { Card } from "../../../components/UI";
import { Form, FormButton } from "../../../components/Forms";
import styles from "./modal.module.css";

const getCheckBoxOptions = (assignedUserList = []) => {
  return assignedUserList.map((assignedUser) => {
    return { key: assignedUser, value: assignedUser };
  });
};

const validationSchema = Yup.object({
  emails: Yup.array()
    .min(1, "Please select at least one email")
    .required("Required")
    .label("Email"),
});

function ResendEmailModal({
  showModal,
  toggleModal,
  title,
  subtitle,
  assignedUserList,
  onConfirm,
  label = "",
  error,
}) {
  if (!showModal) return null;

  return (
    <Portal toggleModal={toggleModal}>
      <div className={styles.resendEmailModal}>
        <Card>
          <div className={styles.modalContainer}>
            {title && <h1 className={styles.heading1}>{title}</h1>}
            {subtitle && <p className="text-normal">{subtitle}</p>}
          </div>
          <Form
            initialValues={{ emails: [] }}
            onSubmit={onConfirm}
            validationSchema={validationSchema}
          >
            <CheckBoxGroup
              label={label}
              name="emails"
              options={getCheckBoxOptions(assignedUserList)}
            />
            <div className={styles.buttonsContainer}>
              <Button
                buttonText="CANCEL"
                isBackground={false}
                onClick={toggleModal}
                type="button"
              />
              <FormButton
                buttonText="Resend Email"
                type="submit"
                submitButton={true}
              />
            </div>
            {error && <p className="error m-10">{error}</p>}
          </Form>
        </Card>
      </div>
    </Portal>
  );
}

export default ResendEmailModal;
