export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// function for returning date in format "23 Jan, 2021"
export function getDateMonthYearFormat(date) {
  const dateObj = new Date(date);
  const month = dateObj.getMonth();
  const date_obj = dateObj.getDate();
  const year = dateObj.getFullYear();
  const str = `${date_obj} ${monthList[month].substring(0, 3)}, ${year} `;
  return str;
}

// function for returning differece between two days
export function getDifferenceInDays(date) {
  var date1 = new Date();
  var date2 = new Date(date);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Math.ceil(Difference_In_Days);
}

export const dayDateMonthFormat = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth();
  const date_obj = dateObj.getDate();
  const day = dateObj.getDay();
  const str = `${days[day]}, ${date_obj} ${monthList[month].substring(0, 3)} `;
  return str;
};
