import { useReducer } from 'react'

const initialState = {
    error: "",
    message: "",
    loading: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SENDING_REQUEST':
            return { ...initialState, loading: true }
        case 'GOT_ERROR':
            return { ...state, loading: false, error: action.payload.error }
        case 'SUCCESS':
            return { ...state, loading: false, message: action.payload.message }
        case "RESET":
            return initialState;
        default:
            throw new Error();
    }

}

function useResponse() {
    const [state, dispatch] = useReducer(reducer, initialState);

    const sendingRequst = () => {
        dispatch({ type: "SENDING_REQUEST" });
    }

    const gotError = (error) => {

        dispatch({ type: "GOT_ERROR", payload: { error: error } });
    }

    const successResponse = (message) => {
        dispatch({ type: "SUCCESS", payload: { message: message } });
    }

    const reset = () => {
        dispatch({ type: "RESET" });
    }

    return [state, { sendingRequst, gotError, successResponse, reset }];
}

export default useResponse
