import React from "react";
import toast from "react-hot-toast";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "../../../components";
import {
  Header,
  NewSection,
  NewSectionQuestionForm,
  SectionQuestion,
  ViewButtonsCard,
  ViewQuestionCard,
} from "../components";
import { addQuestion } from "../../../stores/QuestionnaireReducer";
import { createQuestion } from "../../../services/questionService";
import useVisible from "../../../hooks/useVisible";
import styles from "../components/newSection.module.css";

function Details({ questionnaire }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [createSectionMode, toggleCreateSectionMode] = useVisible(false);
  const [createQuestionMode, toggleCreateQuestionMode] = useVisible(false);

  const buttonList = [
    {
      buttonText: "ADD NEW QUESTION",
      onClick: () => {
        toggleCreateQuestionMode();
      },
    },
    {
      buttonText: "ADD NEW SECTION",
      onClick: () => {
        toggleCreateSectionMode();
      },
    },
  ];

  const handleSubmitNewQuestion = async (newQuestion) => {
    newQuestion.questionnaireId = questionnaire._id;
    try {
      const response = await createQuestion(newQuestion);
      const data = await response.data;
      delete newQuestion.questionnaireId;
      newQuestion._id = data.question._id;
      console.log(data);
      dispatch(
        addQuestion({
          question: {
            _id: newQuestion._id,
            questionName: newQuestion.questionName,
            instruction: newQuestion.instruction,
            responseCharLimit: newQuestion.responseCharLimit,
          },
        })
      );
      toggleCreateQuestionMode();
      toast.success("Question is created!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able to create new Question!");
      }
    }
  };

  return (
    <>
      <Header
        title="Draft Questionnaire"
        subtitle="After your questionnaire is completed, proceed to assigning responders to your questionnaire"
      >
        <Button
          buttonText="ASSIGN RESPONDERS"
          Icon={AiOutlineUserAdd}
          onClick={() => history.push(`/dashboard/${questionnaire._id}/assign`)}
          size={1.8}
        />
      </Header>
      <ViewQuestionCard
        questionnaire={questionnaire}
        questionnaireId={questionnaire._id}
      />

      {questionnaire.sections.map((section, index) => (
        <NewSection
          key={index}
          section={section}
          questionnaireId={questionnaire._id}
        />
      ))}
      {createSectionMode && (
        <NewSection
          newSection={true}
          questionnaireId={questionnaire._id}
          toggleNewSectionForm={toggleCreateSectionMode}
        />
      )}

      {questionnaire?.questions?.map((question, index) => (
        <div className={styles.questionCardContainer} key={index}>
          <div className={styles.container}>
            {/* <div key={index} className={styles.question}> */}
            <SectionQuestion
              question={question}
              hasBorder={true}
              otherData={{
                questionnaireId: questionnaire._id,
              }}
            />
            {/* </div> */}
          </div>
        </div>
      ))}

      {createQuestionMode && (
        <div className={styles.questionCardContainer}>
          <div className={styles.container}>
            <NewSectionQuestionForm
              initialValues={{
                questionName: "",
                instruction: "",
                responseCharLimit: 0,
              }}
              createQuestionMode={createQuestionMode}
              onSubmit={handleSubmitNewQuestion}
              onCancel={() => toggleCreateQuestionMode()}
              hasBorder={true}
            />
          </div>
        </div>
      )}
      <ViewButtonsCard list={buttonList} />
    </>
  );
}

export default Details;
