import React, { useState } from "react";
import styles from "./cardStatus.module.css";

function Progress({ totalQuestion = 35, complateQuestion = 15 }) {
  const [totalProgress, setTotalProgress] = useState(() => {
    if (!complateQuestion && !totalQuestion) return 0;
    return (complateQuestion / totalQuestion) * 100;
  });

  return (
    <div className={`${styles.container} ${styles.activeContainer}`}>
      <p className={styles.containerText}>
        {totalProgress.toFixed(1)}% complete
      </p>
      <p
        className={styles.backgroundStatus}
        style={{ width: `${totalProgress}%` }}
      ></p>
    </div>
  );
}

export default Progress;
