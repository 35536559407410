import React, { useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { CardContainer, Card } from "../../components/UI";
import { Form, FormInput, FormButton } from "../../components/Forms";
import * as userService from "../../services/userService";
import useResponse from "../../hooks/useResponse";
import styles from "./signup.module.css";
import trimObjectValues from "../Dashboard/utils/trimObject";

// signup validationSchema
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required().min(5).label("Full Name").trim(),
  email: Yup.string().required().min(6).email().label("Email").trim(),
  designation: Yup.string()
    .required()
    .min(2)
    .max(30)
    .label("Designation")
    .trim(),
});

// signup page by which user will be able to signup
function Signup() {
  const [state, dispatch] = useResponse();
  const [loading, setLoading] = useState(false);
  const [isSignup, setIsSignedUp] = useState(false);

  const handleSubmit = async (userData, formikObject) => {
    dispatch.sendingRequst();
    setLoading(true);
    userData = trimObjectValues(userData);

    try {
      const response = await userService.register(userData);
      dispatch.successResponse(response.data.message);
      setLoading(false);
      formikObject.resetForm();
      setIsSignedUp(true);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.err);
        setLoading(false);
      }
    }
  };

  let content;

  if (isSignup) {
    content = (
      <div style={{ marginTop: "-20rem", width: "60rem" }}>
        <h5 className="heading4">Welcome to Gather!</h5>
        <h5 className="heading4">
          Your account has been created successfully.
        </h5>
        <h5 className="heading4">
          Please check your email for further instructions.
        </h5>
        <h5 className="heading4">
          Please click{" "}
          <Link to="/login" className="heading4">
            here
          </Link>{" "}
          to Login.
        </h5>
      </div>
    );
  } else {
    content = (
      <Card>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading1}>Create an Account</h1>
          <p>Enter your details below.</p>
        </div>
        <Form
          initialValues={{
            fullName: "",
            email: "",
            designation: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <div className={styles.container}>
            <FormInput label="Your Full Name" name="fullName" type="text" />
            <FormInput label="Work Email" name="email" type="text" />
            <FormInput
              label="Designation"
              name="designation"
              type="text"
              error={state.error}
              success={state.message}
            />
            {loading && (
              <FormButton
                Loadingbuttontext="Loading"
                type="submit"
                submitButton={true}
              />
            )}
            {!loading && (
              <FormButton
                buttonText="give me access"
                type="submit"
                submitButton={true}
              />
            )}
            <Link to="/login">Already have an Account? Sign in </Link>
          </div>
        </Form>
      </Card>
    );
  }

  return <CardContainer>{content}</CardContainer>;
}

export default Signup;
