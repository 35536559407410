import React from "react";
import styles from "./header.module.css";

function Header({ title, subtitle, children }) {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.textContainer}>
        <h4 className="heading4">{title}</h4>
        <p className="text-sm2">{subtitle}</p>
      </div>
      <div className={styles.buttonContainer}>{children}</div>
    </div>
  );
}

export default Header;
