import React from "react";
import { useField, ErrorMessage } from "formik";
import { getDifferenceInDays } from "../utils/date-formats";
import styles from "./formDateField.module.css";
import styles2 from "./formField.module.css";

function getDate(date = new Date()) {
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

function FormDateField({ title, subtitle, ...otherProps }) {
  // const [field] = useField(otherProps);
  const [field] = useField(otherProps);

  return (
    <div className={styles.formGroup}>
      <h4 className="heading4">{title}</h4>
      <p className="text-sm3">{subtitle}</p>
      <div className={styles.inputContainer}>
        <input
          className={styles.formField}
          type="date"
          {...otherProps}
          {...field}
          min={getDate()}
          value={getDate(field.value)}
        />
        <p className="text-sm3">
          Due in {getDifferenceInDays(field.value)} days
        </p>
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className={styles2.errorMessage}
      />
    </div>
  );
}

export default FormDateField;
