import React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  AssignSectionQuestion,
  ClickToAssign,
  ConfirmationModel,
  QuestionnaireButton,
} from ".";
import useVisible from "../../../hooks/useVisible";
import {
  assignToSection,
  assignToSectionIndividualQuestion,
} from "../../../services/assignSectionService";
import { removeAssignedUserAPICall } from "../../../services/removeAssignedUserService";
import {
  assignToSectionAllQuestions,
  assignToSectionQuestions,
  removeAssignedUserFromStore,
} from "../../../stores/QuestionnaireReducer";
import modalText from "../../modalText";
import styles from "./newSection.module.css";

function NewSection({ section, index, questionnaireId }) {
  const [isReadyToRemoveAssignedUser, toggleIsReadyToRemoveAssignedUser] =
    useVisible(false);
  const dispatch = useDispatch();

  const handleRemoveAssignedUserForSection = async (sectionId) => {
    const payload = {
      sectionId,
      questionnaireId,
    };
    try {
      await removeAssignedUserAPICall(payload);
      dispatch(removeAssignedUserFromStore(payload));
      toggleIsReadyToRemoveAssignedUser();
      toast.success("Assign email is removed from section!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // history.push("/not-found");
        toast.error("Not able to assign email!");
      }
    }
  };

  const handleAssignToSectionSubmit = async (newEmail, sectionId) => {
    const payload = {
      sectionId,
      questionnaireId,
      emailAssignToSection: newEmail,
    };
    try {
      await assignToSection(sectionId, payload);
      dispatch(assignToSectionAllQuestions(payload));
      toast.success("Successfully Assigned To Section!");
      // return data;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // history.push("/not-found");
        toast.error("Not able to Assigned To Section!");
      }
    }
  };

  const handleAssignToSectionQuestionSubmit = async (
    newEmail,
    sectionId,
    questionId
  ) => {
    const payload = {
      sectionId,
      questionnaireId,
      emailAssignToSection: newEmail,
    };

    try {
      await assignToSectionIndividualQuestion(questionId, payload);
      payload.questionId = questionId;
      dispatch(assignToSectionQuestions(payload));
      toast.success("Successfully Assigned To Question!");
      // return data;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        // history.push("/not-found");
        toast.error("Not able to Assigned To Question!");
      }
    }
  };

  return (
    <>
      <div className={styles.questionCardContainer} key={section._id}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div>
              <p className={styles.sectionCountText}>Section {index + 1}</p>
              <h2 className={styles.heading2}>{section.sectionName}</h2>
            </div>
            <div className={styles.secondHeaderContainer}>
              <ClickToAssign
                title="assign section to"
                subtitle="Click to assign"
                assignEmail={section.assignedUser || ""}
                onSubmit={(email) =>
                  handleAssignToSectionSubmit(email, section._id)
                }
                isReadyToRemove={isReadyToRemoveAssignedUser}
              />
              {section.assignedUser && (
                <QuestionnaireButton
                  buttonText="remove"
                  buttonType="delete"
                  onClick={toggleIsReadyToRemoveAssignedUser}
                  style={{ marginLeft: "-10rem" }}
                />
              )}
            </div>
          </div>
          <ConfirmationModel
            title={modalText.removeAssign.title}
            subtitle={modalText.removeAssign.subtitle}
            showModal={isReadyToRemoveAssignedUser}
            toggleModal={toggleIsReadyToRemoveAssignedUser}
            onConfirm={() => handleRemoveAssignedUserForSection(section._id)}
          />
          {section.questions.map((question, index) => (
            <div key={question._id} className={styles.question}>
              <AssignSectionQuestion
                question={question}
                onClickToAssign={(email) =>
                  handleAssignToSectionQuestionSubmit(
                    email,
                    section._id,
                    question._id
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default NewSection;
