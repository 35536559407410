import http from "./httpService";

const apiEndpoint = http.apiUrl + "/request-more-info";

function requestMoreInfoURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function requestMoreInfoAPI(requestMoreInfoPayload) {
  return http.patch(
    requestMoreInfoURl(requestMoreInfoPayload.questionId),
    requestMoreInfoPayload
  );
}
