import {
  createSlice,
  createAsyncThunk,
  createSelector,
  current,
} from "@reduxjs/toolkit";
import { getQuestionnaire } from "../services/questionnaireService";

const initialState = {
  loading: true,
  questionnaire: {
    sections: [],
    questions: [],
  },
  err: "",
};

export const fetchSignleQuestionnaire = createAsyncThunk(
  "questionnaire/fetchAll",
  async (questionnaireId) => {
    try {
      const response = await getQuestionnaire(questionnaireId);
      const data = await response.data;
      return data;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }
    }
  }
);

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: initialState,
  reducers: {
    setQuestionnaire: (state, action) => {
      state.loading = false;
      delete state["questionnaire"];
      state["questionnaire"] = action.payload;
      return state;
    },
    setErrorForQuestionnaire: (state, action) => {
      state.loading = false;
      state.error = "Not able to fetch questionnaire info!";
      return state;
    },
    editQuestionnaire: (state, action) => {
      const editedQuestionnaire = action.payload;
      state["questionnaire"] = editedQuestionnaire;
    },
    addQuestionnaire: (state, action) => {
      state.questionnairesList.push(action.payload);
    },
    deleteQuestionnaireFromStore: (state, action) => {
      const filteredQuestionniresList = state.questionnairesList.filter(
        (questionnaire) => questionnaire._id !== action.payload._id
      );
      state.questionnairesList = filteredQuestionniresList;
    },
    addNewSection: (state, action) => {
      state.questionnaire.sections.push(action.payload);
    },
    editSection: (state, action) => {
      const index = state.questionnaire.sections.findIndex(
        (section) => section._id === action.payload._id
      );
      state.questionnaire.sections[index].sectionName =
        action.payload.sectionName;
    },
    removeSection: (state, action) => {
      const { sectionId, option } = action.payload;

      const index = state.questionnaire.sections.findIndex(
        (section) => section._id === sectionId
      );

      const deletedSection = state.questionnaire.sections[index];

      state.questionnaire.sections.splice(index, 1);

      if (option === "2")
        state.questionnaire.questions.push(...deletedSection.questions);
      else
        state.questionnaire.totalQuestions -=
          current(deletedSection).questions.length;
    },
    addQuestion: (state, action) => {
      const { sectionId, question } = action.payload;

      //   if sectionId present then add question inside an section
      //  otherwise add question outside of section
      if (sectionId) {
        const sectionIndex = state.questionnaire.sections.findIndex(
          (section) => section._id === sectionId
        );

        state.questionnaire.sections[sectionIndex].questions.push(question);
        ++state.questionnaire.totalQuestions;
        return;
      }

      ++state.questionnaire.totalQuestions;

      state.questionnaire.questions.push(question);
    },
    editQuestion: (state, action) => {
      const { sectionId, question } = action.payload;

      if (sectionId) {
        const sectionIndex = state.questionnaire.sections.findIndex(
          (section) => section._id === sectionId
        );

        const questionIndex = state.questionnaire.sections[
          sectionIndex
        ].questions.findIndex(
          (questionItem) => questionItem._id === question.questionId
        );

        state.questionnaire.sections[sectionIndex].questions[
          questionIndex
        ].questionName = question.questionName;
        state.questionnaire.sections[sectionIndex].questions[
          questionIndex
        ].instruction = question.instruction;
        state.questionnaire.sections[sectionIndex].questions[
          questionIndex
        ].responseCharLimit = question.responseCharLimit;
        return;
      }

      let index = 0;

      for (
        let questionIndex = 0;
        questionIndex < state.questionnaire.questions.length;
        questionIndex++
      ) {
        if (
          state.questionnaire.questions[questionIndex]._id ===
          question.questionId
        ) {
          index = questionIndex;
        }
      }

      state.questionnaire.questions[index].questionName = question.questionName;
      state.questionnaire.questions[index].instruction = question.instruction;
      state.questionnaire.questions[index].responseCharLimit =
        question.responseCharLimit;
    },
    removeQuestion: (state, action) => {
      const { sectionId, question } = action.payload;

      if (sectionId) {
        const sectionIndex = state.questionnaire.sections.findIndex(
          (section) => section._id === sectionId
        );

        const questionIndex = state.questionnaire.sections[
          sectionIndex
        ].questions.findIndex(
          (questionItem) => questionItem._id === question.questionId
        );

        if (questionIndex === -1) return;

        state.questionnaire.sections[sectionIndex].questions.splice(
          questionIndex,
          1
        );

        --state.questionnaire.totalQuestions;
        return;
      }

      const questionIndex = state.questionnaire.questions.findIndex(
        (questionItem) => questionItem._id === question.questionId
      );

      if (questionIndex === -1) return;

      --state.questionnaire.totalQuestions;

      state.questionnaire.questions.splice(questionIndex, 1);
    },
    assignToAllQuestionnaireQuestionsInStore: (state, action) => {
      const { emailAssignToQuestion } = action.payload;

      // storing previous questionnaire assign email to replace with new email
      const previousQuestionnaireAssignUserVal =
        state.questionnaire.assignedUser;

      state.questionnaire.sections.forEach((section, sectionIndex) => {
        // finding the section question is already assigned or not
        let hasQuestionAssignedToUser = false;
        state.questionnaire.sections[sectionIndex].questions.forEach(
          (question) => {
            if (question.assignedUser && question.isDeleted === false) {
              hasQuestionAssignedToUser = true;
            }
          }
        );

        // assigned to section all question based on if checks
        if (previousQuestionnaireAssignUserVal && hasQuestionAssignedToUser) {
          state.questionnaire.sections[sectionIndex].questions.forEach(
            (question, questionIndex) => {
              if (
                question.assignedUser === previousQuestionnaireAssignUserVal &&
                question.isDeleted === false &&
                question.status !== "completed"
              ) {
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].assignedUser = emailAssignToQuestion;
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].status = "assigned";
              } else if (
                question.assignedUser === "" &&
                question.isDeleted === false &&
                question.status !== "completed"
              ) {
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].assignedUser = emailAssignToQuestion;
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].status = "assigned";
              }
            }
          );
        } else if (
          previousQuestionnaireAssignUserVal === "" &&
          hasQuestionAssignedToUser === true
        ) {
          state.questionnaire.sections[sectionIndex].questions.forEach(
            (question, questionIndex) => {
              if (
                question.assignedUser === "" &&
                question.isDeleted === false &&
                question.status !== "completed"
              ) {
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].assignedUser = emailAssignToQuestion;
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].status = "assigned";
              }
            }
          );
        } else {
          state.questionnaire.sections[sectionIndex].questions.forEach(
            (question, questionIndex) => {
              if (
                question.assignedUser === "" &&
                question.isDeleted === false &&
                question.status !== "completed"
              ) {
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].assignedUser = emailAssignToQuestion;
                state.questionnaire.sections[sectionIndex].questions[
                  questionIndex
                ].status = "assigned";
              }
            }
          );
        }
      });

      // finding the outer question is already assigned or not
      let hasOuterQuestionAssignedToUser = false;
      state.questionnaire.questions.forEach((question) => {
        if (question.assignedUser && question.isDeleted === false) {
          hasOuterQuestionAssignedToUser = true;
        }
      });

      // assigned to outer all question based on if checks
      if (
        previousQuestionnaireAssignUserVal &&
        hasOuterQuestionAssignedToUser
      ) {
        state.questionnaire.questions.forEach((question, questionIndex) => {
          if (
            question.assignedUser === previousQuestionnaireAssignUserVal &&
            question.isDeleted === false &&
            question.status !== "completed"
          ) {
            state.questionnaire.questions[questionIndex].assignedUser =
              emailAssignToQuestion;
            state.questionnaire.questions[questionIndex].status = "assigned";
          } else if (
            question.assignedUser === "" &&
            question.isDeleted === false &&
            question.status !== "completed"
          ) {
            state.questionnaire.questions[questionIndex].assignedUser =
              emailAssignToQuestion;
            state.questionnaire.questions[questionIndex].status = "assigned";
          }
        });
      } else if (
        previousQuestionnaireAssignUserVal === "" &&
        hasOuterQuestionAssignedToUser === true
      ) {
        state.questionnaire.questions.forEach((question, questionIndex) => {
          if (question.assignedUser === "" && question.isDeleted === false) {
            state.questionnaire.questions[questionIndex].assignedUser =
              emailAssignToQuestion;
            state.questionnaire.questions[questionIndex].status = "assigned";
          }
        });
      } else {
        state.questionnaire.questions.forEach((question, questionIndex) => {
          if (
            question.assignedUser === "" &&
            question.isDeleted === false &&
            question.status !== "completed"
          ) {
            state.questionnaire.questions[questionIndex].assignedUser =
              emailAssignToQuestion;
            state.questionnaire.questions[questionIndex].status = "assigned";
          }
        });
      }

      // assign to questionnaire
      state.questionnaire.assignedUser = emailAssignToQuestion;
    },
    assignToOuterQuestion: (state, action) => {
      const { emailAssignToQuestion, questionId } = action.payload;

      const questionIndex = state.questionnaire.questions.findIndex(
        (questionItem) => questionItem._id === questionId
      );

      // assigning to target outer question
      state.questionnaire.questions[questionIndex].assignedUser =
        emailAssignToQuestion;
      state.questionnaire.questions[questionIndex].status = "assigned";
    },
    assignToSectionAllQuestions: (state, action) => {
      const { emailAssignToSection, sectionId } = action.payload;

      // assigning to targeted section with the new email
      const sectionIndex = state.questionnaire.sections.findIndex(
        (section) => section._id === sectionId
      );

      // getting previous section assignedUser email
      const previousSectionAssignedUserVal =
        state.questionnaire.sections[sectionIndex].assignedUser;

      // checking section question is already assigned to someone or not
      let hasQuestionAssignedToUser = false;
      state.questionnaire.sections[sectionIndex].questions.forEach(
        (question) => {
          if (question.assignedUser && question.isDeleted === false) {
            hasQuestionAssignedToUser = true;
          }
        }
      );

      // assigning to all section questions based on if checks
      if (previousSectionAssignedUserVal && hasQuestionAssignedToUser) {
        state.questionnaire.sections[sectionIndex].questions.forEach(
          (question, questionIndex) => {
            if (
              question.assignedUser === previousSectionAssignedUserVal &&
              question.isDeleted === false &&
              question.status !== "completed"
            ) {
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].assignedUser = emailAssignToSection;
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].status = "assigned";
            } else if (
              question.assignedUser === "" &&
              question.isDeleted === false &&
              question.status !== "completed"
            ) {
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].assignedUser = emailAssignToSection;
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].status = "assigned";
            }
          }
        );
      } else if (
        previousSectionAssignedUserVal === "" &&
        hasQuestionAssignedToUser === true
      ) {
        state.questionnaire.sections[sectionIndex].questions.forEach(
          (question, questionIndex) => {
            if (
              question.assignedUser === "" &&
              question.isDeleted === false &&
              question.status !== "completed"
            ) {
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].assignedUser = emailAssignToSection;
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].status = "assigned";
            }
          }
        );
      } else {
        state.questionnaire.sections[sectionIndex].questions.forEach(
          (question, questionIndex) => {
            if (
              question.assignedUser === "" &&
              question.isDeleted === false &&
              question.status !== "completed"
            ) {
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].assignedUser = emailAssignToSection;
              state.questionnaire.sections[sectionIndex].questions[
                questionIndex
              ].status = "assigned";
            }
          }
        );
      }

      // changing to section assignedUser email with new assignedUser email
      state.questionnaire.sections[sectionIndex].assignedUser =
        emailAssignToSection;
    },
    assignToSectionQuestions: (state, action) => {
      const { emailAssignToSection, sectionId, questionId } = action.payload;
      const sectionIndex = state.questionnaire.sections.findIndex(
        (section) => section._id === sectionId
      );

      // finding perticuler section question
      const questionIndex = state.questionnaire.sections[
        sectionIndex
      ].questions.findIndex((questionItem) => questionItem._id === questionId);

      // assigning to perticular section question, and changing status to assigned
      state.questionnaire.sections[sectionIndex].questions[
        questionIndex
      ].assignedUser = emailAssignToSection;

      state.questionnaire.sections[sectionIndex].questions[
        questionIndex
      ].status = "assigned";
    },
    pauseAndEditQuestionnaire: (state, action) => {
      state.questionnaire.status = "pause";
    },
    removeAssignedUserFromStore: (state, action) => {
      const { sectionId } = action.payload;

      if (sectionId) {
        const sectionIndex = state.questionnaire.sections.findIndex(
          (section) => section._id === sectionId
        );

        state.questionnaire.sections[sectionIndex].assignedUser = "";

        return;
      }

      state.questionnaire.assignedUser = "";
    },
    acceptResponseOfQuestion: (state, action) => {
      const { sectionId, questionId } = action.payload;

      // if question present inside an section
      // that means it is section question otherwise it is an outer question
      if (sectionId) {
        // finding index of  section
        const sectionIndex = state.questionnaire.sections.findIndex(
          (section) => section._id === sectionId
        );

        // finding the index of section question
        const questionIndex = state.questionnaire.sections[
          sectionIndex
        ].questions.findIndex(
          (questionItem) => questionItem._id === questionId
        );

        // accepting the response and changing question status to "completed"
        state.questionnaire.sections[sectionIndex].questions[
          questionIndex
        ].status = "completed";
      } else {
        const questionIndex = state.questionnaire.questions.findIndex(
          (questionItem) => questionItem._id === questionId
        );

        state.questionnaire.questions[questionIndex].status = "completed";
      }

      ++state.questionnaire.answeredQuestions;
    },
    requestMoreInfoForResponse: (state, action) => {
      const { sectionId, questionId, additionalInfo } = action.payload;

      // if question present inside an section
      // that means it is section question otherwise it is an outer question
      if (sectionId) {
        // finding index of  section
        const sectionIndex = state.questionnaire.sections.findIndex(
          (section) => section._id === sectionId
        );

        // finding the index of section question
        const questionIndex = state.questionnaire.sections[
          sectionIndex
        ].questions.findIndex(
          (questionItem) => questionItem._id === questionId
        );

        // asking for more info  and changing question status to "awaitingInfo"
        state.questionnaire.sections[sectionIndex].questions[
          questionIndex
        ].status = "awaitingInfo";

        // asking for more info and pushing it into answers array
        state.questionnaire.sections[sectionIndex].questions[
          questionIndex
        ].answer.push({
          type: "additionalInfo",
          answerText: additionalInfo,
          createdDate: new Date().toString(),
        });
      } else {
        const questionIndex = state.questionnaire.questions.findIndex(
          (questionItem) => questionItem._id === questionId
        );

        // asking for more info  and changing question status to "awaitingInfo"
        state.questionnaire.questions[questionIndex].status = "awaitingInfo";

        // asking for more info and pushing it into answers array
        state.questionnaire.questions[questionIndex].answer.push({
          type: "additionalInfo",
          answerText: additionalInfo,
          createdDate: new Date().toString(),
        });
      }
    },
  },
});

// extraReducers: (builder) => {
//     builder.addCase(fetchSignleQuestionnaire.fulfilled, (state, action) => {
//         console.log(action.payload);
//         state.loading = false;
//         state.questionnaire = action.payload.questionnaire;
//     })
//     builder.addCase(fetchSignleQuestionnaire.rejected, (state, action) => {
//         state.loading = false;
//         // state.error = action.payload;
//     })
// },

// checking all the provided question has assignedUser or not
// If all questions has assignedUser then return 'true' otherwise 'false'
function hasQuestionsAssign(questions) {
  if (questions.length === 0) return false;

  let isQuestionsAssign = true;
  for (let index = 0; index < questions.length; index++) {
    if (!questions[index].assignedUser) {
      return false;
    } else {
      isQuestionsAssign = true;
    }
  }

  return isQuestionsAssign;
}

// function for checking all section and it's questions has assignedUser or not
function checkAllSectionQuestionIsAssign(sections) {
  let isSectionsAssign = true;
  if (sections.length === 0) return isSectionsAssign;

  for (let index = 0; index < sections.length; index++) {
    if (sections[index].questions.length !== 0) {
      isSectionsAssign = hasQuestionsAssign(sections[index].questions);
      if (!isSectionsAssign) return false;
    }
  }
  return isSectionsAssign;
}

export const hasAbleToGatherResponse = createSelector(
  (state) => state?.entities.questionnaire.questionnaire,
  (questionnaire) => {
    if (
      questionnaire.questions.length === 0 &&
      questionnaire.sections.length === 0
    )
      return false;

    const isOuterQuestionsAssign = hasQuestionsAssign(questionnaire.questions);
    const isSectionQuestionAssign = checkAllSectionQuestionIsAssign(
      questionnaire.sections
    );

    if (questionnaire.questions.length === 0)
      return isOuterQuestionsAssign || isSectionQuestionAssign;

    return isOuterQuestionsAssign && isSectionQuestionAssign;
  }
);

export const getCountOfAllTotalQuestionsStatus = createSelector(
  (state) => state?.entities.questionnaire.questionnaire,
  (questionnaire) => {
    const statusList = {
      open: 0,
      click: 0,
      notAssigned: 0,
      notDelivered: 0,
      viewed: 0,
      delivered: 0,
      readyForReview: 0,
      awaitingInfo: 0,
      declined: 0,
      completed: 0,
      assigned: 0,
      "in-progress": 0,
    };

    const countQuestionBasedOnStatus = (questions) => {
      for (let index = 0; index < questions.length; index++) {
        const questionStatus = questions[index].status;

        statusList[questionStatus] = statusList[questionStatus] + 1;
      }
    };

    const countSectionQuestionBasedOnStatus = (sections) => {
      for (let index = 0; index < sections.length; index++) {
        countQuestionBasedOnStatus(sections[index].questions);
      }
    };

    countQuestionBasedOnStatus(questionnaire.questions);
    countSectionQuestionBasedOnStatus(questionnaire.sections);
    return statusList;
  }
);

export const getAllDistinctAssignedUser = createSelector(
  (state) => state?.entities.questionnaire.questionnaire,
  (questionnaire) => {
    let distinctAssignedUserObject = {};

    if (questionnaire.questions.length !== 0) {
      questionnaire.questions.forEach((question) => {
        const assignedUser = question.assignedUser;

        if (
          !distinctAssignedUserObject[assignedUser] &&
          question.status !== "completed" &&
          question.status !== "readyForReview" &&
          question.status !== "declined"
        ) {
          distinctAssignedUserObject[assignedUser] = 1;
        }
      });
    }

    if (questionnaire.sections.length !== 0) {
      questionnaire.sections.forEach((section) => {
        if (section.questions.length !== 0) {
          section.questions.forEach((question) => {
            const assignedUser = question.assignedUser;

            if (
              !distinctAssignedUserObject[assignedUser] &&
              question.status !== "completed" &&
              question.status !== "readyForReview" &&
              question.status !== "declined"
            ) {
              distinctAssignedUserObject[assignedUser] = 1;
            }
          });
        }
      });
    }

    return Object.keys(distinctAssignedUserObject);
  }
);

export const {
  setQuestionnaire,
  setErrorForQuestionnaire,
  editQuestionnaire,
  addQuestionnaire,
  deleteQuestionnaireFromStore,
  addNewSection,
  editSection,
  removeSection,
  addQuestion,
  editQuestion,
  removeQuestion,
  assignToAllQuestionnaireQuestionsInStore,
  assignToOuterQuestion,
  assignToSectionAllQuestions,
  assignToSectionQuestions,
  removeAssignedUserFromStore,
  pauseAndEditQuestionnaire,
  acceptResponseOfQuestion,
  requestMoreInfoForResponse,
} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
