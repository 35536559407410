"strict mode";

import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Dashboard from "./pages/Dashboard/Index";
import NotFound from "./components/NotFound";
import AppToken from "./components/AppToken";
import EmailInvite from "./pages/EmailInvite/EmailInvite";
import auth from "./services/authService";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const currUser = auth.getCurrentUser();
    setUser(() => {
      return currUser;
    });
  }, [setUser]);

  return (
    <div className="app">
      <Switch>
        <Redirect from="/" exact to="/signup" />
        <GuestRoute path="/signup" component={Signup} />
        <GuestRoute path="/login" component={Login} />
        <Route path="/app/:token" component={AppToken} />
        <Route path="/responder/:token" component={AppToken} />
        <ProtectedRoute path="/dashboard/" component={Dashboard} user={user} />
        <Route path="/email-invite/:token" component={EmailInvite} />
        <Route path="/not-found" component={NotFound} />
        <Redirect to="/not-found" />
      </Switch>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 5000,
          style: {
            fontSize: "2rem",
          },
        }}
      />
    </div>
  );
}

export default App;

const ProtectedRoute = ({
  path,
  component: Component,
  user,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        return Component ? <Component user={user} {...props} /> : render(props);
      }}
    />
  );
};

const GuestRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getCurrentUser())
          return Component ? <Component {...props} /> : render(props);
        return (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
