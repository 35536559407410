import React from "react";
import styles from "./movingButton.module.css";

function MovingButton({ onClick, title, isDisable = true }) {
  return (
    <button
      className={`${styles.button} ${isDisable ? styles.disableButton : ""}`}
      onClick={onClick}
      disabled={isDisable}
    >
      {title}
    </button>
  );
}

export default MovingButton;
