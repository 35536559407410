import React from "react";
import { Progress, ResponseSectionQuestion } from ".";
import styles from "./newSection.module.css";

const countCompletedQuestionOfSection = ({ questions }) => {
  if (!questions?.length === 0 || !questions) return 0;

  let countCompletedQuestions = 0;
  questions.forEach((question) => {
    if (question.status === "completed") countCompletedQuestions++;
  });

  return countCompletedQuestions;
};

function ResponseSection({ section, index, questionnaireId, dueDate }) {
  const numcomletedQuestions = countCompletedQuestionOfSection(section);

  return (
    <div className={styles.questionCardContainer}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div>
            <p className={styles.sectionCountText}>Section {index + 1}</p>
            <h2 className={styles.heading2}>{section.sectionName}</h2>
          </div>
          <Progress
            complateQuestion={numcomletedQuestions}
            totalQuestion={section?.questions?.length}
          />
        </div>
        {section.questions.map((question, index) => (
          <div key={index} className={styles.question}>
            <ResponseSectionQuestion
              question={question}
              questionnaireId={questionnaireId}
              sectionId={section._id}
              dueDate={dueDate}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResponseSection;
