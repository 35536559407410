import http from "./httpService";

const apiEndpoint = http.apiUrl + "/response";

function responseToQuestionURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function responseToQuestionByAPI(questionId, responseData, token) {
  return http.put(responseToQuestionURl(questionId), responseData, {
    headers: { "x-responder-token": token },
  });
}
