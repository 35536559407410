import React from "react";

function QuestionList({ questions }) {
  return (
    <>
      {questions.map((question) => (
        <>
          {question.status !== "readyForReview" && (
            <h5
              className="heading5"
              key={question._id}
              style={{ marginBottom: "1rem" }}
            >
              <span className="capitallize"> - {question.questionName}</span>
            </h5>
          )}
        </>
      ))}
    </>
  );
}

export default QuestionList;
