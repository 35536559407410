import React from "react";
import styles from "./questionnaireButton.module.css";

function QuestionnaireButton({
  buttonType = "view",
  onClick,
  buttonText = "edit",
  ...otherProps
}) {
  if (buttonType === "delete" || buttonType === "edit") {
    return (
      <button
        onClick={onClick}
        className={`${styles.button} ${styles.deleteButton}`}
        {...otherProps}
      >
        {buttonText}
      </button>
    );
  }

  if (buttonType === "reuse") {
    return (
      <button onClick={onClick} className={styles.button} {...otherProps}>
        {buttonText}
      </button>
    );
  }

  if (buttonType === "view") {
    return (
      <button
        onClick={onClick}
        className={`${styles.button} ${styles.viewButton}`}
        {...otherProps}
      >
        {buttonText}
      </button>
    );
  }

  if (buttonType === "viewWithWhite") {
    return (
      <button
        onClick={onClick}
        className={`${styles.button} ${styles.viewWhiteButton}`}
        {...otherProps}
      >
        {buttonText}
      </button>
    );
  }
}

export default QuestionnaireButton;
