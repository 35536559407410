import http from "./httpService";

const apiEndpoint = http.apiUrl + "/responder-questionnaire";

function responderQuestionnaireURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function fetchAllResponderAssignQuestionnaires() {
  return http.get(apiEndpoint);
}

export function getTokenForAssignQuestionnaire(questionnaireId) {
  return http.get(responderQuestionnaireURl(questionnaireId));
}
