import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import ResponsesOfQuestionnaire from "./ResponsesOfQuestionnaire";
import Details from "./Details";
import {
  setQuestionnaire,
  setErrorForQuestionnaire,
} from "../../../stores/QuestionnaireReducer";
import { getQuestionnaire } from "../../../services/questionnaireService";

const fetchQuestionnaire = (id, history) => async (dispatch) => {
  try {
    const response = await getQuestionnaire(id);
    const data = await response.data;
    dispatch(setQuestionnaire(data.questionnaire));
    return data;
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      history.push("/not-found");
    }
    dispatch(setErrorForQuestionnaire());
  }
};

function DetailQuestion() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const state = useSelector((state) => state);
  const { loading, error, questionnaire } = state.entities.questionnaire;

  useEffect(() => {
    // dispatch(fetchSignleQuestionnaire(id));
    dispatch(fetchQuestionnaire(id, history));
  }, [dispatch, id, history]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="heading3 text-center">{error}</h3>;
  }

  if (
    questionnaire.status === "active" ||
    questionnaire.status === "completed"
  ) {
    return <ResponsesOfQuestionnaire questionnaire={questionnaire} />;
  }

  return <Details questionnaire={questionnaire} />;
}

export default DetailQuestion;
