import React from "react";
import { Button } from "../../../components";
import styles from "./viewButtonsCard.module.css";

function ViewButtonsCard({ list = [] }) {
  return (
    <div className={styles.cardContainer}>
      {list.map((item, index) => (
        <Button
          key={index}
          buttonText={item.buttonText}
          onClick={item.onClick}
          size={1.8}
          borderRadius={3}
        />
      ))}
    </div>
  );
}

export default ViewButtonsCard;
