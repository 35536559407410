import http from "./httpService";

const apiEndpoint = http.apiUrl + "/assign/section";

function assignSectionORSectionQuestionURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function assignToSection(sectionId, payload) {
  return http.put(assignSectionORSectionQuestionURl(sectionId), payload);
}

export function assignToSectionIndividualQuestion(questionId, payload) {
  return http.patch(assignSectionORSectionQuestionURl(questionId), payload);
}
