import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "../../../components/UI";
import Question from "./Question";
import MovingButtonContainer from "./MovingButtonContainer";
import styles from "./questionCard.module.css";

function QuestionCard({ questions = [], index }) {
  const history = useHistory();
  const [currQuestionNum, setCurrQuestionNum] = useState(() => {
    return parseInt(index) < 0
      ? 0
      : parseInt(index) >= questions.length
      ? questions.length - 1
      : parseInt(index);
  });

  const [tryForResponse, setTryForResponse] = useState(false);
  const { token } = useSelector((state) => state.entities.responder);

  useEffect(() => {
    localStorage.removeItem("currQuestion");
  }, []);

  if (questions.length === 0) {
    return <div>No Question Assigned to you.</div>;
  }

  const setReadyForResponse = () => {
    setTryForResponse(true);
  };

  const nextQuestion = () => {
    setCurrQuestionNum((prev) => {
      prev++;
      return prev;
    });

    history.push(`/email-invite/response/${token}/${parseInt(index) + 1}`);
  };

  const prevQuestion = () => {
    setCurrQuestionNum((prev) => {
      prev--;
      return prev;
    });

    history.push(`/email-invite/response/${token}/${parseInt(index) - 1}`);
  };

  const moveToNextQuestion = () => {
    if (currQuestionNum < questions.length - 1) {
      setCurrQuestionNum((prev) => {
        prev++;
        return prev;
      });
    }
  };

  const moveToPrevQuestion = () => {
    if (currQuestionNum === questions.length - 1) {
      prevQuestion();
    }
  };

  return (
    <>
      {questions[currQuestionNum] ? (
        <Card>
          <div className={styles.container}>
            <div className={styles.firstChild}>
              <p className="text-sm2 text-sm2-bold">
                QUESTION {currQuestionNum + 1}/{questions.length}
              </p>
              <MovingButtonContainer
                onNext={nextQuestion}
                onPrev={prevQuestion}
                MaxQuestionLength={questions.length}
                currQuestionNum={currQuestionNum}
              />
            </div>
            <div className={styles.middleChild}>
              <Question
                question={questions[currQuestionNum]}
                token={token}
                nextQuestion={moveToNextQuestion}
                prevQuestion={moveToPrevQuestion}
                setTryForResponse={setReadyForResponse}
                toggleTryForResponse={() => {
                  setTryForResponse(false);
                }}
                tryForResponse={tryForResponse}
              />
            </div>
            <div className={styles.lastChild}>
              <MovingButtonContainer
                onNext={nextQuestion}
                onPrev={prevQuestion}
                MaxQuestionLength={questions.length}
                currQuestionNum={currQuestionNum}
              />
            </div>
          </div>
        </Card>
      ) : (
        <h3 className="heading text-center">No question found!</h3>
      )}
    </>
  );
}

export default QuestionCard;
