import http from "./httpService";

const apiEndpoint = http.apiUrl + "/declined";

export function declinedQuestionsByAPI(token, email) {
  return http.post(
    apiEndpoint,
    { anotherEmail: email },
    { headers: { "x-responder-token": token } }
  );
}
