import React from "react";
import ReactDOM from "react-dom";
import styles from "./modal.module.css";

/*
 * Portal Component -
 * @param {toggleModal} -> for toggling the modal if user click on black overlay arround the modal.
 * @param {children} -> react child node element which we need to show in modal.
 * @desc This component used to create Portal as name suggest
 */
function Portal({ toggleModal, children }) {
  return ReactDOM.createPortal(
    <>
      <div className={styles.modaloverlay} onClick={toggleModal}></div>
      {children}
    </>,
    document.getElementById("app-modal")
  );
}

export default Portal;
