import React, { useState } from "react";
import * as Yup from "yup";
import { CardContainer, Card } from "../../components/UI";
import { Form, FormInput, FormButton } from "../../components/Forms";
import { RadioButtonGroup } from "../Dashboard/components";
import * as authService from "../../services/authService";
import useResponse from "../../hooks/useResponse";
import styles from "./login.module.css";
import trimObjectValues from "../Dashboard/utils/trimObject";

const radioOptions = [
  { key: "Questionnaire Admin", value: "main" },
  { key: "Questionnaire Responder", value: "assign" },
];

const validationSchema = Yup.object().shape({
  email: Yup.string().required().min(6).email().label("Email").trim(),
  option: Yup.string().required("Select any one option to login"),
});

// Login Page by which user will be able to login
function Login() {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useResponse();

  const handleSubmit = async (loginData) => {
    dispatch.sendingRequst();
    setLoading(true);

    // triming object
    loginData = trimObjectValues(loginData);

    try {
      const response = await authService.login(loginData);
      dispatch.successResponse(response.data.message);
      setLoading(false);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.err);
      }
      setLoading(false);
    }
  };

  return (
    <CardContainer>
      <Card>
        <Form
          initialValues={{
            email: "",
            option: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <div className={styles.container}>
            <h5 className={styles.heading5}>
              Enter your registered email to sign-in
            </h5>
            <FormInput
              label="Work Email"
              name="email"
              type="text"
              error={state.error}
              success={state.message}
            />
            <div>
              <RadioButtonGroup
                // label="Please select any below option for deleting section"
                name="option"
                options={radioOptions}
              />
            </div>

            {loading && (
              <FormButton
                Loadingbuttontext="Loading"
                type="submit"
                submitButton={true}
              />
            )}
            {!loading && (
              <FormButton
                buttonText="give me access"
                type="submit"
                submitButton={true}
              />
            )}
          </div>
        </Form>
      </Card>
      <div className={`${styles.loginText} text-sm1`}>
        <p className="text--bold">Secure Authentication.</p>
        <p>
          We will email you a magic link that will let you access your Gather
          account.
        </p>
        <p>
          This prevents anyone who cannot access their work email from accessing
          the data on Gather.
        </p>
      </div>
    </CardContainer>
  );
}

export default Login;
