import React from "react";
import * as Yup from "yup";
import { Form } from "../../../components/Forms";
import { Button } from "../../../components";
import { FormField } from ".";
import styles from "./newSectionQuestionForm.module.css";
import styles2 from "./assignSectionQuestion.module.css";
import trimObjectValues from "../utils/trimObject";

const validationSchema = Yup.object().shape({
  additionalInfo: Yup.string().required().min(5).label("More Info").trim(),
});

function MoreInfoForm({ initialValues, onSubmit, onCancel }) {
  return (
    <div className={styles2.moreInfoForm}>
      <Form
        initialValues={initialValues}
        onSubmit={(data, otherFormObj) => {
          data = trimObjectValues(data);
          onSubmit(data, otherFormObj);
        }}
        validationSchema={validationSchema}
      >
        <div className={`${styles.formContainer} ${styles2.formContainer}`}>
          <FormField
            autoFocus={true}
            title="Request More Info"
            element="textarea"
            name="additionalInfo"
            row={10}
          />
        </div>
        <div className={styles2.acceptOrMoreInfoContainer}>
          <div className={styles2.containerText}>
            <p className="text-sm2" style={{ color: "black" }}>
              Request more information or provide more instructions by typing
              out your message to the responder.
            </p>
          </div>
          <div className={styles2.buttonsContainer}>
            <Button
              buttonText={"cancel"}
              isBackground={false}
              size={1.8}
              onClick={onCancel}
              type="button"
            />
            <Button buttonText={"send request "} size={1.8} type="submit" />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default MoreInfoForm;
