import http from "./httpService";

const apiEndpoint = http.apiUrl + "/word-file";

function generateWordFileURl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getGeneratedDocFileAPI(questionnaireId) {
  return http.get(generateWordFileURl(questionnaireId), {
    responseType: "blob",
  });
}

export function generatedDocFileAPI(questionnaireId) {
  return http.post(generateWordFileURl(questionnaireId));
}
