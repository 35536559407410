import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Container from "./Container";
import { Loader } from "../../components";
import { QuestionCard } from "./component";
import {
  setAllAssignedQuestionsAndOtherDetails,
  setErrorState,
  setLoadingState,
} from "../../stores/responderReducer";
import { getCurrentResponder } from "../../services/authService";
import { fetchAllAssignedQuestions } from "../../services/responderService";
import styles from "../Dashboard/components/viewQuestionCard.module.css";
import AdminDetailCard from "./component/AdminDetailCard";

const fetchQuestions = (history, token) => async (dispatch) => {
  dispatch(setLoadingState(true));

  try {
    const response = await fetchAllAssignedQuestions(token);
    const data = await response.data;

    dispatch(
      setAllAssignedQuestionsAndOtherDetails({
        questions: data.assignedQuestionList,
        questionnaire: data.questionnaire,
        user: data.user,
        token: token,
      })
    );
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      history.push("/not-found");
    } else {
      dispatch(setErrorState());
    }
  }
};

function AssignedAnswer() {
  const { token, questionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [responder, setResponder] = useState(null);
  const { questions, isLoading, user, error, questionnaire } = useSelector(
    (state) => state.entities.responder
  );

  useEffect(() => {
    const currResponder = getCurrentResponder(token);
    setResponder(() => {
      return currResponder;
    });
  }, [setResponder, token]);

  useEffect(() => {
    dispatch(fetchQuestions(history, token));
  }, [dispatch, history, token]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="heading3 text-center"> {error}</h3>;
  }

  if (!user && !questions) {
    return <Loader />;
  }

  let errorText;

  if (questions.length === 0) {
    errorText = (
      <h3 className="heading3 text-center">
        Unfortunately, there are no questions for you to review. Please contact
        admin of the questionnaire if needed.
      </h3>
    );
  }

  if (questionnaire.status === "pause") {
    errorText = (
      <h3 className="heading3 text-center">
        The admin of the questionnaire has paused the responses temporarily.
      </h3>
    );
  }

  if (moment().isAfter(questionnaire.dueDate)) {
    errorText = (
      <h3 className="heading3 text-center">
        We are sorry. The requested questionnaire has passed the due date.
      </h3>
    );
  }

  return (
    <Container email={responder?.assignedUser}>
      {user && questions ? (
        <>
          {errorText ? (
            errorText
          ) : (
            <>
              <AdminDetailCard user={user} dueDate={questionnaire.dueDate} />
              <div
                className={styles.questionCardContainer}
                style={{ border: "none" }}
              >
                <QuestionCard questions={questions} index={questionId} />
              </div>
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
}

export default AssignedAnswer;
