import React from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Button } from "../../../components";
import {
  EditSectionTitle,
  NewSectionQuestionForm,
  QuestionnaireButton,
  SectionQuestion,
  SectionConfirmationModal,
} from ".";
import {
  createSection,
  deleteSection,
  updateSection,
} from "../../../services/sectionService";
import { createQuestion } from "../../../services/questionService";
import {
  addNewSection,
  editSection,
  removeSection,
  addQuestion,
} from "../../../stores/QuestionnaireReducer";
import useVisible from "../../../hooks/useVisible";
import modalText from "../../modalText";
import styles from "./newSection.module.css";

function NewSection({
  questionnaireId,
  section = { sectionName: "" },
  sectionTitle = "new section name",
  toggleNewSectionForm,
  newSection = false,
}) {
  const [isEditMode, toggleIsEditMode] = useVisible(newSection);
  const [createQuestionMode, toggleCreateQuestionMode] = useVisible(false);
  const [isConfirmVisible, toggleConfirm] = useVisible(false);
  const dispatch = useDispatch();

  const handleSubmitNewQuestion = async (newQuestion) => {
    newQuestion.questionnaireId = questionnaireId;
    newQuestion.sectionId = section._id;

    try {
      const response = await createQuestion(newQuestion);
      const data = await response.data;

      delete newQuestion.questionnaireId;
      newQuestion._id = data.question._id;

      dispatch(
        addQuestion({
          sectionId: section._id,
          question: {
            _id: newQuestion._id,
            questionName: newQuestion.questionName,
            instruction: newQuestion.instruction,
            responseCharLimit: newQuestion.responseCharLimit,
          },
        })
      );

      toggleCreateQuestionMode();
      toast.success("Question is created!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able to created Question!");
      }
    }
  };

  const handleCreateSections = async (newSection, obj) => {
    newSection.questionnaireId = questionnaireId;
    try {
      const response = await createSection(newSection);
      const data = await response.data;

      delete newSection.questionnaireId;
      newSection._id = data.section._id;

      newSection.questions = [];
      dispatch(addNewSection(newSection));

      obj.resetForm();
      toggleNewSectionForm();

      toast.success("Section is created!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
      }

      toast.error("Not able to created Section!");
    }
  };

  const handleEditSection = async (editedSection) => {
    try {
      await updateSection(editedSection._id, {
        questionnaireId,
        sectionName: editedSection.sectionName,
      });

      dispatch(editSection(editedSection));
      toggleIsEditMode();
      toast.success("Successfully section edited!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        console.log(ex.response.data);
        toast.error("Not able to edited section!");
      }
    }
  };

  const handleDeleteSection = async ({ option }) => {
    try {
      await deleteSection(section._id, {
        questionnaireId,
        option,
      });

      dispatch(removeSection({ sectionId: section._id, option }));
      toggleConfirm();
      toast.success("Successfully deleted section!");
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        toast.error("Not able to delete section!");
      }
    }
  };

  return (
    <>
      <div className={styles.questionCardContainer}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            {!isEditMode ? (
              <>
                <h2 className={styles.heading2}>
                  {section?.sectionName || sectionTitle}
                </h2>
                <div className={styles.buttonsContainer}>
                  <QuestionnaireButton
                    buttonType="edit"
                    onClick={toggleIsEditMode}
                  />
                  <QuestionnaireButton
                    buttonText="delete"
                    buttonType="delete"
                    onClick={toggleConfirm}
                  />
                </div>
              </>
            ) : (
              <>
                <EditSectionTitle
                  section={section}
                  editMode={isEditMode}
                  onCancel={
                    newSection ? toggleNewSectionForm : toggleIsEditMode
                  }
                  onSubmit={
                    newSection ? handleCreateSections : handleEditSection
                  }
                />
              </>
            )}
          </div>
          {section?.questions?.map((question, index) => (
            <div key={index} className={styles.question}>
              <SectionQuestion
                question={question}
                otherData={{
                  questionnaireId: questionnaireId,
                  sectionId: section._id,
                }}
              />
            </div>
          ))}
          <NewSectionQuestionForm
            initialValues={{
              questionName: "",
              instruction: "",
              responseCharLimit: 0,
            }}
            createQuestionMode={createQuestionMode}
            onSubmit={handleSubmitNewQuestion}
            onCancel={() => toggleCreateQuestionMode()}
          />
          {!newSection && (
            <div className={styles.buttonContainer}>
              <Button
                buttonText="Add a question"
                onClick={toggleCreateQuestionMode}
                borderRadius={4}
                isBackground={false}
              />
            </div>
          )}
        </div>
      </div>
      <SectionConfirmationModal
        title={modalText.sectionDelete.title}
        subtitle={modalText.sectionDelete.subtitle}
        showModal={isConfirmVisible}
        toggleModal={toggleConfirm}
        onConfirm={handleDeleteSection}
      />
    </>
  );
}

export default NewSection;
