import { createSlice } from '@reduxjs/toolkit';

const initialData = null;
const userReducer = createSlice({
    name: "appearance",
    initialState: initialData,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        }
    }
});

export const { setUser } = userReducer.actions;
export default userReducer.reducer;




