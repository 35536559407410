import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { getQuestionnaires } from "../services/questionnaireService";

const initialState = {
  loading: true,
  questionnairesList: [],
  error: "",
};

export const fetchQuestionnaires = createAsyncThunk(
  "questionnaire/fetchAll",
  async () => {
    const response = await getQuestionnaires();
    const data = await response.data;

    return data;
  }
);

const questionnaireSlice = createSlice({
  name: "questionnaires",
  initialState: initialState,
  reducers: {
    setAllQuestionnaires: (state, action) => {
      return action.payload;
    },
    addQuestionnaire: (state, action) => {
      state.questionnairesList.push(action.payload);
    },
    changeActivity: (state, action) => {
      const { title, url, spotlight } = action.payload;
      const index = action.payload.activityKey;
      state[index] = { title, url, spotlight };
    },
    deleteQuestionnaireFromStore: (state, action) => {
      const filteredQuestionniresList = state.questionnairesList.filter(
        (questionnaire) => questionnaire._id !== action.payload._id
      );
      state.questionnairesList = filteredQuestionniresList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionnaires.pending, (state, action) => {
        // console.log("Fetching data in and promise in Pending state!");
      })
      .addCase(fetchQuestionnaires.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.loading = false;
        state.questionnairesList = action.payload.questionnaireList;
      })
      .addCase(fetchQuestionnaires.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const getDraftQuestionnaires = createSelector(
  (state) => state?.entities.questionnaires.questionnairesList,
  (questionnaires) =>
    questionnaires.filter((questionnaire) => questionnaire.status === "draft")
);

// export const getDraftQuestionnaires = (state) => {
//   const questionnaires = state?.entities?.questionnaires?.questionnairesList;
//   console.log(state);
//   console.log(questionnaires);
//   return questionnaires?.filter(
//     (questionnaire) => questionnaire.status === "draft"
//   );
//   // return questionnaires;
// };

export const getActiveQuestionnaires = createSelector(
  (state) => state?.entities.questionnaires.questionnairesList,
  (questionnaires) =>
    questionnaires.filter(
      (questionnaire) =>
        questionnaire.status === "active" || questionnaire.status === "pause"
    )
);

export const getCompletedQuestionnaires = createSelector(
  (state) => state?.entities.questionnaires.questionnairesList,
  (questionnaires) =>
    questionnaires.filter(
      (questionnaire) => questionnaire.status === "completed"
    )
);

export const {
  setAllQuestionnaires,
  addQuestionnaire,
  deleteQuestionnaireFromStore,
} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
