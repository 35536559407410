import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  error: "",
  responder: {},
  questions: [],
  questionnaire: {},
};

const responderSlice = createSlice({
  name: "responder",
  initialState: initialState,
  reducers: {
    setResponderTokenData: (state, action) => {
      const { responder, token } = action.payload;
      state.responder = responder;
      state.token = token;
      state.isLoading = false;
    },
    setAllAssignedQuestionsAndOtherDetails: (state, action) => {
      const { questions, questionnaire, user, token } = action.payload;
      state.questions = questions;
      state.questionnaire = questionnaire;
      state.user = user;
      state.token = token;
      state.isLoading = false;
    },

    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },

    setErrorState: (state, action) => {
      state.isLoading = false;
      state.error = "Something went wrong!";
    },
    answerToQuestion: (state, action) => {
      const { questionId, answer, status } = action.payload;
      const questionIndex = state.questions.findIndex(
        (questionItem) => questionItem._id === questionId
      );

      if (questionIndex === -1) return;

      if (state.questions[questionIndex].answer.length === 0) {
        state.questions[questionIndex].answer.push(answer);
      } else {
        const answerLen = state.questions[questionIndex].answer.length;
        const lastAnswer = state.questions[questionIndex].answer[answerLen - 1];

        if (lastAnswer._id === answer._id) {
          state.questions[questionIndex].answer[answerLen - 1] = answer;
        } else {
          state.questions[questionIndex].answer.push(answer);
        }
      }

      state.questions[questionIndex].status = status;
    },
    removeQuestion: (state, action) => {
      const { questionId } = action.payload;
      const questionIndex = state.questions.findIndex(
        (questionItem) => questionItem._id === questionId
      );

      state.questions.splice(questionIndex, 1);
    },
  },
});

export const getAdditionalInfoQuestionIndex = createSelector(
  (state) => state?.entities.responder,
  (responder) => {
    if (responder.questions.length !== 0) {
      const additionalInfoQuestionId = responder.responder.additionInfo;

      const index = responder.questions.findIndex(
        (question) => question._id === additionalInfoQuestionId
      );

      return index;
    }
  }
);

export const getAllQuestions = createSelector(
  (state) => state?.entities.responder,
  (responder) => console.log(responder.questions)
);

/*
 * assign,click, in-progress, awaitingInfo
 *
 */
export const getCountNotSubmittedQuestion = createSelector(
  (state) => state?.entities.responder,
  (responder) => {
    if (responder.questions.length !== 0) {
      let count = 0;
      responder.questions.forEach((question) => {
        const questionStatus = question.status;
        if (["click", "in-progress", "awaitingInfo"].includes(questionStatus)) {
          count++;
        }
      });
      return count;
    }
  }
);
export const {
  setResponderTokenData,
  setAllAssignedQuestionsAndOtherDetails,
  setLoadingState,
  setErrorState,
  answerToQuestion,
  removeQuestion,
} = responderSlice.actions;
export default responderSlice.reducer;
