import React from "react";

function AdditionalInfoQuestion({ question }) {
  const answerListLength = question.answer.length;
  return (
    <>
      <h5 className="heading5" style={{ marginBottom: "1rem" }}>
        <span className="capitallize">{question.questionName}</span>
      </h5>
      <h4 className="heading4">Asking For More Info, See Below Summary -</h4>
      {answerListLength > 1 ? (
        <>
          {question.answer[answerListLength - 1].type === "additionalInfo" && (
            <p className="text-sm1">
              {question.answer[answerListLength - 1].answerText}
            </p>
          )}
          {question.answer[answerListLength - 2].type === "additionalInfo" && (
            <p className="text-sm1">
              {question.answer[answerListLength - 2].answerText}
            </p>
          )}
        </>
      ) : null}
    </>
  );
}

export default AdditionalInfoQuestion;
