import React from "react";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Group 5.png";
import styles from "./navbar.module.css";

function Navbar({ email }) {
  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img src={Logo} alt="gather logo" height={30} width={170} />
          </Link>
          <h5 className="heading5">
            {email && <span>{email}</span>}
            <FiCheck
              color="white"
              className={`${styles.icon} ${styles.success}`}
            />
          </h5>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
