import React from "react";
import moment from "moment";
import styles from "../../Dashboard/components/viewQuestionCard.module.css";

function AdminDetailCard({ user, dueDate }) {
  return (
    <div className={styles.questionCardContainer}>
      <div
        className={styles.questionTextContainer}
        style={{ borderBottom: "none" }}
      >
        <div className={styles.questionHeadingContainer}>
          <div>
            <h2
              className={styles.heading2}
              style={{ fontSize: "2.4rem", textTransform: "initial" }}
            >
              <label className="capitallize">{user?.fullName}</label>,{" "}
              <label className="capitallize">{user?.designation}</label> (
              {user?.email}) is requesting information from you
            </h2>
            <h5 className="heading5">
              Please respond to this before the due-date on{" "}
              <b>{moment(dueDate).format("Do MMM, YYYY")}</b>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDetailCard;
