import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = http.apiUrl + "/auth";

const tokenKey = "token";
const responderKey = "resToken";

http.setJwt(getJwt());
http.setResonderJwt(getResonderJwt());

export function login(emailObject) {
  return http.post(apiEndpoint, emailObject);
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getCurrentResponder(token) {
  try {
    // const jwt = localStorage.getItem(responderKey);
    const responder = jwtDecode(token);
    // console.log("responder : ", responder);
    return responder;
  } catch (ex) {
    return null;
  }
}

export function removeResponderToken() {
  localStorage.removeItem(responderKey);
}

export function setJWT(jwt) {
  http.setJwt(getJwt());
  sessionStorage.setItem(tokenKey, jwt);
}

export function getJwt() {
  return sessionStorage.getItem(tokenKey);
}

export function setResponderJWT(jwt) {
  http.setResonderJwt(jwt);
  localStorage.setItem(responderKey, jwt);
}

export function getResonderJwt() {
  return localStorage.getItem(responderKey);
}

const authObject = {
  login,
  logout,
  setJWT,
  getCurrentUser,
  getJwt,
  setResponderJWT,
  removeResponderToken,
  getCurrentResponder,
  getResonderJwt,
};

export default authObject;
