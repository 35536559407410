import React from "react";
import { QuestionnaireButton } from ".";
import styles from "./questionnaireCardButtons.module.css";

function QuestionnairesCardButtons({
  onDeleteClick,
  onReuseClick,
  onViewClick,
  questionnaireStatus,
}) {
  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.firstButtonContainer}>
        <QuestionnaireButton
          buttonText="delete"
          buttonType="delete"
          onClick={onDeleteClick}
        />
        <QuestionnaireButton
          buttonText="reuse"
          buttonType="reuse"
          onClick={onReuseClick}
        />
      </div>
      {questionnaireStatus !== "completed" ? (
        <QuestionnaireButton buttonText="view" onClick={onViewClick} />
      ) : (
        <QuestionnaireButton
          buttonType="viewWithWhite"
          buttonText="view"
          onClick={onViewClick}
        />
      )}
    </div>
  );
}

export default QuestionnairesCardButtons;
