import React from "react";
import { FiCheck } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { ErrorMessage, useField } from "formik";
import styles from "./input.module.css";

function FormInput({
  label,
  signal,
  showError = true,
  error,
  success,
  ...otherProps
}) {
  const [field, meta] = useField(otherProps);

  let className;
  let icon;

  if (meta.touched && meta.error) {
    className = "error";
    icon = (
      <div className={styles.iconContainer}>
        <FiX color="white" className={`${styles.icon} ${styles[className]}`} />
      </div>
    );
  } else if (meta.touched && !meta.error) {
    className = "success";
    icon = (
      <div className={styles.iconContainer}>
        <FiCheck
          color="white"
          className={`${styles.icon} ${styles[className]}`}
        />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={`${styles.container} ${styles[className]}`}>
        <input
          type="text"
          className={styles.inputText}
          required
          {...otherProps}
          {...field}
        />
        <span className={styles.floatingLabel}>{label}</span>
        {icon && icon}
      </div>
      {showError && (
        <ErrorMessage
          component="div"
          name={field.name}
          className={styles.errorMessage}
        />
      )}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
}

export default FormInput;
